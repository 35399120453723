import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { BarCharts } from './BarCharts';
import { LineChart } from './LineCharts';
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot } from "firebase/firestore";
import { PieChart } from "./PieChart";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Input, RadioGroup, Textarea, Checkbox, CheckboxFollowup } from "../Components";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { saveAs } from "file-saver";
import { Col, DropdownButton, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { getRGB, makeid, random_rgb, random_rgba } from "../Automatic Forms/Utils";
import { RadarChart } from "./RadarChart";
import { colorFamily, getRandomColor } from "../Fixed Sources/colorScales";
import { AiFillEye, AiFillSave, AiOutlineSave } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { FaEdit } from "react-icons/fa";
import { WordCloud } from "./WordCloud";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-info"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
const CustomToggle2 = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-warning"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
const CustomToggleChartSelection = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-secondary"
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>{children}
        &#x25bc;</Button>
));
// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().includes(value),
                    )}
                </ul>
            </div>
        );
    },
);
export function BivariableCharts({
    latestAnswerDict,
    rawData,
    formData, handleShowStatusModal = null }) {
    //Drop-down
    const chartRef = React.createRef();
    const [chartID] = useState(makeid(6));
    const excludedKeys = ['formID', 'formSubmissionID', 'createdAt', 'userID']

    const {
        register,
        watch,
        formState: { errors },
        getValues,
        setError,
        clearErrors,
    } = useFormContext();

    const stacked = watch(`${chartID}-stacked`)
    console.log('stacked', stacked);
    const [saveSuccessfully, setSaveSuccessfully] = useState(false)
    useEffect(() => {
        console.log('chartRef', chartRef);


        if (chartRef.current) {
            // console.log('chartRef chartInstance',  chartRef.current.toBase64Image());

        }
    }, [chartRef])

    // const [chartTitle, setChartTitle] = useState(null);
    const chartTitle = watch('chartTitle');
    const chartDescription = watch('chartDescription');

    // const [chartDescription, setChartDescription] = useState(null);

    const [chartDimension, setChartDimension] = useState({
        width: 500,
        height: 500
    });

    const [currentEventKey, setCurrentEventKey] = useState(null)
    const [currentEventKey2, setCurrentEventKey2] = useState(null)
    const [randomColor, setRandomColor] = useState(getRGB(getRandomColor()))

    const [allVariables, setAllVariables] = useState([]);


    const { formId } = useParams();
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Bi-Variable Chart',
            },
        },
    })

    let formDict = {}
    if (formData) {
        formDict = formData.form_questions.reduce((acc, question) => {
            acc[question.questionID] = question.question_text;
            return acc;
        }, {});
    }
    const supportedCharts = ['Bar Chart', 'Pie Chart', 'Line Chart', 'Radar Chart', 'Word Cloud']
    const supportedChartsDouble = ['Bar Chart']

    const [currentSelectedChart, setCurrentSelectedChart] = useState(supportedCharts[0])
    useEffect(() => {
        if (!supportedChartsDouble.includes(currentSelectedChart)) {
            setCurrentEventKey2(null) //reset secondary key if chart doesn't support 2 dimensions
        }
    }, [currentSelectedChart])
    useEffect(() => {
        if (chartTitle && currentSelectedChart) {
            // console.log(chartData)

            setChartData({
                ...chartData,
                chartType: currentSelectedChart,
                title: chartTitle,
                description: chartDescription ? chartDescription : ''
            })
            setChartOptions({
                ...chartOptions, plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: chartTitle,
                    },
                },
            })
        }
    }, [chartTitle, currentSelectedChart, chartDescription])
    useEffect(() => {
        if (chartDimension) {
            // console.log(chartData)
            setChartData({
                ...chartData,
                dimension: chartDimension,
                // labels: Object.keys(aggregatedData),
                // values: Object.values(aggregatedData)
            })
        }
    }, [chartDimension])
    useEffect(() => {
        if (chartData) {
            console.log('chartData', chartData)
        }
    }, [chartData])
    useEffect(() => {
        if (allVariables.length > 0) {
            // console.log(allVariables)
            if (currentEventKey == null) {
                setCurrentEventKey(allVariables[0]);
            }
        }
    }, [allVariables])
    useEffect(() => {
        // console.log('latestAnswerDict', latestAnswerDict)
        if (currentEventKey && latestAnswerDict) {
            console.log(currentEventKey, currentEventKey2)

            if (currentEventKey != currentEventKey2 && currentEventKey2 != null && currentEventKey2.toLowerCase() != 'null') {
                // double variable chart
                let allSecondaryValues = new Set(latestAnswerDict[currentEventKey2])
                allSecondaryValues = Array.from(allSecondaryValues)
                let uniquePrimaryXValues = new Set(latestAnswerDict[currentEventKey])
                const aggregateDict = {}
                allSecondaryValues.forEach(val => {
                    aggregateDict[val] = {}
                    uniquePrimaryXValues.forEach(prim_val => {
                        aggregateDict[val][prim_val] = 0;
                    })
                })

                if (rawData && rawData.length > 0) {
                    rawData.forEach(data => {
                        let primaryKeyValue = data[currentEventKey]
                        let secondaryKeyValue = data[currentEventKey2]

                        if (typeof primaryKeyValue === 'object') { //first key is checkbox answer
                            for (const [eachKey, eachValue] of Object.entries(primaryKeyValue)) {
                                if (typeof secondaryKeyValue === 'object') {
                                    for (const [eachKey2, eachValue2] of Object.entries(secondaryKeyValue)) {
                                        if (typeof eachValue2 === 'string' && typeof eachValue === 'string')
                                            aggregateDict[eachValue2][eachValue] += 1 //both are answers to checkboxes
                                    }
                                }
                                else {
                                    if (typeof secondaryKeyValue === 'string' && typeof eachValue === 'string')
                                        aggregateDict[secondaryKeyValue][eachValue] += 1 //just the primary key is checkbox

                                }
                            }
                        }
                        else if (typeof primaryKeyValue === 'string') { //first key is a normal answer
                            if (typeof secondaryKeyValue === 'object') { //second key is checkbox answer
                                for (const [eachKey2, eachValue2] of Object.entries(secondaryKeyValue)) {
                                    // console.log('eachValue2', eachValue2)
                                    if (typeof eachValue2 === 'string')
                                        aggregateDict[eachValue2][primaryKeyValue] += 1 //both are answers to checkboxes
                                }
                            }
                            else { //second key is normal answer
                                if (aggregateDict[secondaryKeyValue])
                                    aggregateDict[secondaryKeyValue][primaryKeyValue] += 1
                                else {

                                    aggregateDict[secondaryKeyValue] = { primaryKeyValue: 0 }
                                }
                            }
                        }
                        else {
                            console.log('not supported', primaryKeyValue)
                        }


                        // console.log('data item', data[currentEventKey])
                        // aggregateDict[secondaryKeyValue][primaryKeyValue] += 1
                    })

                }
                console.log(aggregateDict)
                const backgroundColors = [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'limegreen', 'pink'
                ]
                let datasets = []
                const labels = Array.from(uniquePrimaryXValues)
                allSecondaryValues.forEach((label, index) => {
                    datasets.push({
                        label: label,
                        data: Object.values(aggregateDict[label]),
                        backgroundColor: backgroundColors[index % backgroundColors.length]
                    })
                })

                const chartData = {
                    labels,
                    datasets: datasets,
                    chartType: currentSelectedChart ? currentSelectedChart : 'Bar Chart',
                    chartMode: 'double',
                };
                setChartData({
                    ...chartData,
                    title: `${currentEventKey} by ${currentEventKey2}`,
                    data: chartData,
                    dimension: chartDimension
                })
                setChartOptions({
                    ...chartOptions, plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: `${currentEventKey} by ${currentEventKey2}`,
                        },
                    },
                })
                console.log(datasets)

            }
            else {
                //univariable charts

                const mainKey = currentEventKey

                let aggregatedData = {}
                const uniqueValues = new Set(latestAnswerDict[mainKey])
                uniqueValues.forEach(val => {
                    if (!val || val.trim() == '') {
                        val = 'unknown'
                    }
                    aggregatedData[val] = 0

                })
                console.log('mainKey', mainKey, formDict[mainKey])
                // console.log('latestAnswerDict', latestAnswerDict)
                if (latestAnswerDict[mainKey]) {
                    latestAnswerDict[mainKey].forEach(val => {
                        if (!val || val.trim() == '') {
                            val = 'unknown'
                        }
                        aggregatedData[val] += 1
                    })
                }
                // console.log('aggregatedData', aggregatedData)

                setChartData({
                    title: formDict[mainKey] ? formDict[mainKey] : mainKey,
                    data: aggregatedData,
                    chartType: currentSelectedChart ? currentSelectedChart : 'Bar Chart'
                })


            }
            // console.log('here')
            // const mainKey = currentEventKey

            // let aggregatedData = {}
            // const uniqueValues = new Set(latestAnswerDict[mainKey])
            // uniqueValues.forEach(val => {
            //     aggregatedData[val] = 0

            // })
            // latestAnswerDict[mainKey].forEach(val => {
            //     aggregatedData[val] += 1
            // })

            // setChartData({
            //     title: mainKey,
            //     data: aggregatedData,
            // })


        }

    }, [currentEventKey, currentEventKey2, latestAnswerDict, currentSelectedChart])
    useEffect(() => {
        if (latestAnswerDict) {
            let allKeys = Object.keys(latestAnswerDict);
            allKeys.sort();
            setAllVariables(allKeys)
        }
    }, [latestAnswerDict])

    const saveCanvas = () => {
        //save to png
        const canvasSave = document.getElementById('mainChart');

        // const canvasSave = chartRef.current;
        console.log('canvasSave', canvasSave)

        canvasSave.toBlob(function (blob) {
            saveAs(blob, "testing.png")
        })
    }
    const RenderSelectedChart = ({ chartType, chartData, chartRef }) => {
        if (chartType == 'Pie Chart') {
            const numberOfRandomColors = 10;
            let randomColors = JSON.parse(JSON.stringify(colorFamily));
            randomColors = randomColors.map(getRGB);
            [...Array(numberOfRandomColors)].forEach((e, i) => {
                const random_color = random_rgb();
                randomColors.push(random_color)
            });
            chartData.color = randomColors;
            chartData.alpha = 0.4;
            console.log('chartData.color pie', chartData.color)
            return <PieChart chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Bar Chart') {
            chartData.color = randomColor;
            chartData.alpha = 0.4;
            console.log('chartData.color bar', chartData.color)

            return <BarCharts chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Line Chart') {
            // chartData.color = randomColor;
            chartData.color = randomColor;
            chartData.alpha = 0.4;
            return <LineChart chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Radar Chart') {
            // chartData.color = randomColor;
            chartData.color = randomColor;
            chartData.alpha = 0.4;
            return <RadarChart chartData={chartData} chartRef={chartRef} />
        }
        else if (chartType == 'Word Cloud') {
            return <WordCloud chartData={chartData} chartRef={chartRef}/>
        }
        return <></>
    }

    // const allVariables = ['Red', 'Blue', 'Orange', 'Red-Orange']
    const BiVariableChartsRendering = ({ primaryVariable, secondaryVariable, chartData, chartRef, currentSelectedChart }) => {
        console.log(chartData)
        // console.log('hello')
        if (chartData) {
            if (primaryVariable) {

                if (secondaryVariable && secondaryVariable != primaryVariable) {
                    if (secondaryVariable.toLowerCase() != 'null') {
                        console.log(chartData.data)
                        if (currentSelectedChart == 'Bar Chart') {
                            let localChartOptions = chartOptions;

                            if (stacked) {
                                localChartOptions = {
                                    ...chartOptions, scales: {
                                        x: {
                                            stacked: true,
                                        },
                                        y: {
                                            stacked: true,
                                        },
                                    },
                                }
                            }
                            else {
                                localChartOptions = {
                                    ...chartOptions, scales: {
                                        x: {
                                            stacked: false,
                                        },
                                        y: {
                                            stacked: false,
                                        },
                                    },
                                }
                            }
                            return <div className='col-md-12'>
                                {/* <Line options={chartOptions} data={chartData.data}
                                height="400px"
                                width="500px"
                                ref = {chartRef}
                                id = 'mainChart'
                            /> */}

                                <Bar options={localChartOptions} data={chartData.data}
                                    height="400px"
                                    width="500px"
                                    ref={chartRef}
                                // id='mainChart'
                                />
                            </div>
                        }

                    }
                }
                else {
                    console.log('Single Variable Only!')
                    return <RenderSelectedChart chartType={currentSelectedChart} chartData={chartData} chartRef={chartRef} />
                }
            }

        }
        return <></>

    }
    const MemoizedBiVariableChartsRendering = React.memo(BiVariableChartsRendering);
    const writeToFirebase = (chartData) => {
        const docRef = doc(db, "chart_library", chartID);
        let passed = true;
        if (!chartTitle || chartTitle == '') {
            setError(`chartTitle-${chartID}`, { type: 'custom', message: 'Chart title must not be empty!' });
            passed = false;
        }
        if (!chartDescription || chartDescription == '') {
            setError(`chartDescription-${chartID}`, { type: 'custom', message: 'Chart description must not be empty!' });
            passed = false;

        }
        if (passed) {
            clearErrors(`chartTitle-${chartID}`)
            clearErrors(`chartDescription-${chartID}`)
            console.log('saving...', chartData);
            setSaveSuccessfully(true);
            let finalChartData = {
                ...chartData,
                chartID,
                title: chartTitle,
                description: chartDescription,
                status: 'unpublished'
            }
            setDoc(docRef, finalChartData).then(() => {
                setSaveSuccessfully(true);
                if (handleShowStatusModal) {
                    handleShowStatusModal(`Chart ${chartID} written to Firebase successfully. 
                    Please click Edit Chart's Publishing Settings to publish the chart.`)
                }
                // alert(`${chartID} written to Firebase successfully. Please click Edit Chart's Publishing Settings to publish the chart.`)
            });
        }

    }
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [saveSuccessfully]);

    const filteredVariables = allVariables.filter(variable => {
        const question = formData.form_questions.find(q => q.questionID === variable);
        return question && question.hide !== true;
    });

    return <div style={{ minHeight: 600 }}>
        {/* <LineChart /> */}
        <ReactTooltip />
        <Row>
            <h4 className='text-center'>Chart ID: {chartID}</h4>
        </Row>
        <Row>
            <Col md='3'>
                <Row>
                    <Col md='12'>
                        <Dropdown onSelect={(eventKey, event) => {
                            setCurrentSelectedChart(eventKey)
                        }}>
                            <Dropdown.Toggle as={CustomToggleChartSelection} id="dropdown-custom-components">
                                Select Chart Type {currentSelectedChart ? `(${currentSelectedChart}) ` : ' '}
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomMenu} >
                                {supportedCharts.map(chartType => {
                                    return <Dropdown.Item eventKey={chartType} active={chartType == currentSelectedChart}>{chartType}</Dropdown.Item>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col md='12'>
                        {currentSelectedChart != 'Pie Chart' && <div className='col-md-3' style={{ textAlign: 'center', alignItems: 'center', display: 'flex' }}>
                            <Dropdown onSelect={(eventKey, event) => {
                                console.log('EventKey', eventKey)
                                setRandomColor(getRGB(eventKey))
                            }}>
                                <Dropdown.Toggle variant='outline-secondary'>
                                    Chart Color: <div class="square-family" style={{ backgroundColor: randomColor }}></div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {colorFamily.map(color => {
                                        return <Dropdown.Item eventKey={color} active={randomColor == color}>
                                            <div class="square-family" style={{ backgroundColor: color }}></div>
                                        </Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>}
                    </Col>
                </Row>


            </Col>
            <Col md='7' >
                <Row >
                    <Col md='3' className='center-text-container' >
                        <label htmlFor={'chart-name'} className={`form-label-inline`}>
                            Chart Name:
                        </label>
                    </Col>
                    <Col md='9'>
                        <div>
                            <input
                                id='chart-name'
                                type={'text'}
                                className={'form-control '}
                                placeholder={'Chart Name Here...'}
                                // onChange={(event) => {
                                //     setChartTitle(event.target.value);
                                // }}
                                {...register('chartTitle')}
                            />


                        </div>
                        <div className="is-invalid">

                        </div>
                        <div className="invalid-feedback mb-2">
                            {errors[`chartTitle-${chartID}`] &&
                                errors[`chartTitle-${chartID}`].message}
                        </div>
                    </Col>

                </Row>
                <Row className='mt-2'>
                    <Col md='3' className='center-text-container' >
                        <label htmlFor={'chart-description'} className={`form-label-inline`}>
                            Description:
                        </label>
                    </Col>
                    <Col md='9'>
                        <div>
                            <textarea
                                id='chart-description'
                                type={'text'}
                                rows="4"
                                className={'form-control '}
                                placeholder={'Chart Description Here...'}
                                // onChange={(event) => {
                                //     setChartDescription(event.target.value);
                                // }}
                                {...register('chartDescription')}

                            />
                            <div className="is-invalid">

                            </div>
                            <div className="invalid-feedback mb-2">
                                {errors[`chartDescription-${chartID}`] &&
                                    errors[`chartDescription-${chartID}`].message}
                            </div>

                        </div>
                    </Col>
                </Row>


            </Col>

            <Col md='2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex' }}>
                    {/* {saveSuccessfully ? 'block' : 'hidden'} */}
                    <Link style={{ display: saveSuccessfully ? 'block' : 'none', textDecoration: 'none', color: 'black' }} to={`/charts/${chartID}`} target="_blank" rel="noopener noreferrer">
                        <AiFillEye style={{ marginTop: 0, fontSize: 32, cursor: 'pointer', color: '#474d4a' }} data-tip="See real-time chart rendering" />
                    </Link>


                    <Link style={{ display: saveSuccessfully ? 'block' : 'none', textDecoration: 'none', color: 'black' }} to={`/charts/${chartID}/edit`} target="_blank" rel="noopener noreferrer">
                        <FaEdit style={{ fontSize: 28, cursor: 'pointer', marginBottom: 6, color: '#474d4a', marginLeft: 7 }} data-tip="Edit chart's publishing settings" />
                    </Link>

                    <AiFillSave style={{ color: '#474d4a', fontSize: 29, cursor: 'pointer', marginLeft: 3, marginTop: 1 }} onClick={() => { writeToFirebase(chartData); }} data-tip='Save chart to database' />
                </div>
            </Col>

        </Row>
        <br></br>
        <Row>
            <Col md='3'>
                <Dropdown className='' onSelect={(eventKey, event) => { setCurrentEventKey(eventKey) }}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        Select Primary Variable {currentEventKey ? `(${formDict[currentEventKey]}) ` : ' '}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu} >
                        {filteredVariables.map(primVar => {
                        {/* {allVariables.map(primVar => { */}
                            if (!excludedKeys.includes(primVar))
                                return <Dropdown.Item eventKey={primVar} active={primVar == currentEventKey}>{formDict[primVar]}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>

            <Col md='3'>
                {supportedChartsDouble.includes(currentSelectedChart) &&
                    <Dropdown onSelect={(eventKey, event) => {
                        if (currentEventKey2 != eventKey) {
                            if (eventKey != 'None')
                                setCurrentEventKey2(eventKey);
                            else {
                                setCurrentEventKey2(null);

                            }
                            setChartData(null)
                        }

                    }}>
                        <Dropdown.Toggle as={CustomToggle2} id="dropdown-custom-components">
                            Select Secondary Variable {currentEventKey2 ? `(${currentEventKey2}) ` : ' (None)'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu} >
                            <Dropdown.Item eventKey={'None'} active={currentEventKey2 == 'None'}>{'None'}</Dropdown.Item>

                            {filteredVariables.map(variable => {
                            {/* {allVariables.map(variable => { */}
                                if (!excludedKeys.includes(variable) && variable != currentEventKey) {
                                    return <Dropdown.Item eventKey={variable} active={variable == currentEventKey2}>{formDict[variable]}</Dropdown.Item>
                                }
                            })}
                        </Dropdown.Menu>
                    </Dropdown>}
            </Col>


            <Col md='1' className='center-text-container'>
                {supportedChartsDouble.includes(currentSelectedChart)
                    && currentEventKey2 &&
                    <div className='form-check'>

                        <label>Stacked</label>

                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`${chartID}-stacked`}
                            // value={true}
                            value={`${chartID}-stacked`}
                            // defaultChecked={defaultCheckValue}
                            {...register(`${chartID}-stacked`)}
                        />




                    </div>}
            </Col>
        </Row>

        <br></br>
        <MemoizedBiVariableChartsRendering
            primaryVariable={currentEventKey}
            secondaryVariable={currentEventKey2}
            chartData={chartData}
            chartRef={chartRef}
            currentSelectedChart={currentSelectedChart}
        />

        {/* <RenderSelectedChart chartType={currentSelectedChart} chartData={chartData} /> */}


    </div>
}
