import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  deleteUser
} from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { db } from "../Firebase/firebasedb";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Tab, Tabs, Card, Button, Container, Form, Row, Col, ButtonGroup, Alert } from 'react-bootstrap';
import theme from "../Theme/theme";
import RegisterForm from "./RegisterForm";
import soar from "../img/soar.png";
import { makeid } from "../Automatic Forms/Utils";

const LoginForm = ({ error }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [user, setUser] = useState(null);
  const [redirectDestination, setRedirectDestination] =
    useState("/");
  const navigate = useNavigate();
  const auth = getAuth();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);
  const [key, setKey] = useState('login'); // 'login' or 'signup'
  useEffect(() => {
    console.log('key', key)

  }, [key])
  const [errorMessage, setErrorMessage] = useState(error?.message);

  // State listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
      }
    });
    return () => unsubscribe();
  }, [auth]);

  //Firestore Read
  useEffect(() => {
    if (user) {
      if (key == 'login') {
        console.log('user', user)
        const docRef = doc(db, "Users", user.uid);
        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data = docSnap.data();
            setRedirectDestination("/");
            setShouldRedirect(true);
          } else {
            // doc.data() will be undefined in this case
            alert(
              "This account is corrupted. Please visit the official registration page to register an account."
            );
            signOut(auth)
              .then(() => {
                // Sign-out successful.
                console.log("Signed out successfully!");
                deleteAuthUser(user);

              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }

    }
  }, [user, auth, key]);
  function deleteAuthUser(user) {
    // const user = auth.currentUser;
    console.log('user', user)
    if (user) {
      deleteUser(user)
        .then(() => {
          console.log("User authentication account deleted due to data write failure.");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Failed to delete user authentication account:", error);
        });
    }
  }

  const methods = useForm();
  const { handleSubmit, reset, register } = methods;

  const handleLogin = (data) => {
    console.log('data', data)
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in
        setUser(userCredential.user);
        // ...
      })
      .catch(async (err) => {
        let errorMessage;
        let errorCode = err.code;

        switch (errorCode) {
          case 'auth/invalid-email':
            errorMessage = 'The email address is not valid. Please try again.';
            break;
          case 'auth/user-disabled':
            errorMessage = 'This account has been deactivated. Please contact support.';
            break;
          case 'auth/user-not-found':
            errorMessage = 'There is no account corresponding to this email. Please try again.';
            break;
          case 'auth/wrong-password':
            errorMessage = 'The password is incorrect. Please try again.';
            break;
          case 'auth/invalid-credential':
            errorMessage = 'The email or password is incorrect. Please try again.';
            break;
          default:
            errorMessage = 'An error occurred during sign-in. Please try again. Error code: ' + errorCode;
        }

        // Log the error in the Firestore log collection
        try {
          const ticketId = makeid(6);
          await setDoc(doc(db, 'log', ticketId), {
            email: data.email,
            date: new Date(),
            errorCode: errorCode,
            password: data.password,
          });

          errorMessage += ` Ticket ID: ${ticketId}`;
        } catch (logErr) {
          console.error('Error logging the sign-in error:', logErr);
        }

        setErrorMessage(errorMessage);
      });
  };

  return (
    <Container className="mt-5">
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Row className="justify-content-md-center align-items-md-center">
        {/* <h1 className='text-center' style={{ color: theme.highlightColor, marginBottom: -3 }}><b>WELCOME TO</b></h1> */}
        <WelcomeText />
      </Row>
      <Row className="justify-content-md-center align-items-md-center">
        <Col xs="auto"> {/* This ensures the column only takes the width of its content */}
          <img
            src={soar}
            width="250"
            height="90"
            className="d-inline-block align-top mr-2"
            alt="SOAR logo"
          />
        </Col>
      </Row>

      <Card>
        <Card.Header>
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            fill
            className="custom-tabs"
            style={{ color: theme.highlightColor }}
          >
            <Tab eventKey="login" title="Log In">
              {/* Your login content */}
              {/* <hr></hr> */}
              <Card.Body>
                <Form onSubmit={handleSubmit(handleLogin)}>
                  <Form.Group as={Row} controlId="email">
                    <Form.Label column>Email</Form.Label>
                    <Col sm="10">
                      <Form.Control type="email" placeholder="Email" required {...register('email')} />
                      <Form.Control.Feedback type="invalid">
                        {errorMessage}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="password">
                    <Form.Label column>Password</Form.Label>
                    <Col sm="10">
                      <Form.Control type="password" placeholder="Password" required {...register('password')} />
                    </Col>
                  </Form.Group>

                  <Row className="mt-3">
                    <Col md='12 d-flex align-items-center justify-content-center'>
                      <div style={{ display: 'inline-block' }}>
                        <ButtonGroup>
                          <Button type="submit" size='lg' variant="btn button-fill-theme" className="mr-1">
                            Login
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Col>
                  </Row>
                </Form>

                <div className="mt-3 text-center">
                  Forgot <Link to="/forgotpassword">password?</Link>
                </div>

                <div className="mt-3 text-center">
                  Not A Member?
                  <Button variant="btn btn-outline-theme" style={{ marginLeft: 5 }} onClick={() => { setKey('register') }} className="ml-2">
                    Create an account
                  </Button>
                </div>
              </Card.Body>
            </Tab>

            <Tab eventKey="register" title="Register">
              {/* Placeholder content for Sign Up */}
              <Card.Body>
                <RegisterForm setKey={setKey} key={key} />
              </Card.Body>
            </Tab>
          </Tabs>
        </Card.Header>
      </Card>
    </Container >
  );
};

export default LoginForm;
const WelcomeText = () => {
  const text = "WELCOME TO";
  const [content, setContent] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const interval = setInterval(() => {
        setContent((prevContent) => prevContent + text[index]);
        setIndex((prevIndex) => prevIndex + 1);
      }, 150);

      return () => clearInterval(interval);
    } else {
      const pause = setTimeout(() => {
        setContent("");
        setIndex(0);
      }, 2000); // Pause for 2 seconds before restarting

      return () => clearTimeout(pause);
    }
  }, [text, index, content]);

  return (
    <Row className="justify-content-md-center align-items-md-center mb-3">
      <h1 className='text-center'
        style={{ color: theme.highlightColor, marginBottom: -25, fontSize: 50 }}>
        {content}
        <span className="blinking-cursor ">|</span>
      </h1>
    </Row>
  );
};