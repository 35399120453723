import { generateRandomNicknames, isBase64Encoded, RoleValidationComponent } from "../Automatic Forms/Utils"
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { NavBar } from "./NavBar"
import './Input.css'
import { db } from "../Firebase/firebasedb";
import { doc, getDoc, setDoc, onSnapshot, serverTimestamp, query, collection, deleteDoc } from "firebase/firestore";
import { allRolesRegular, allRoles, isHubLead, allRolesReverse, allRolesCompat, isStudentOrMentor } from "../Fixed Sources/accountTypes";
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Modal } from "react-bootstrap";
import { BsBoxArrowInLeft, BsEyeglasses, BsFillSlashCircleFill, BsPencilSquare } from "react-icons/bs";
import { AiFillSave, AiFillQuestionCircle, AiFillEdit, AiOutlineDelete, AiOutlineReload } from "react-icons/ai";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { faker } from '@faker-js/faker';
// import { institutionsList } from "../Fixed Sources/institutions"
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { base64Decode, base64Encode } from "@firebase/util";
import ReactTooltip from "react-tooltip";
import axios from "axios";
// const flaskServerURL = 'https://soar-lzlb5fvlsa-uc.a.run.app'
// Disable user
const disableUserInServer = async (uid) => {
    try {
        const response = await axios.post(`https://disable-user-lzlb5fvlsa-uc.a.run.app`, { uid: uid });
        return response.data;
    } catch (error) {
        throw new Error("Error disabling user:" + error);
    }
}

const enableUserInServer = async (uid) => {
    try {
        const response = await axios.post(`https://enable-user-lzlb5fvlsa-uc.a.run.app`, { uid: uid });
        return response.data;
    } catch (error) {
        throw new Error("Error enabling user:" + error);
    }
}
const deleteUserInServer = async (uid) => {
    try {
        const response = await axios.post(`https://delete-user-lzlb5fvlsa-uc.a.run.app`, { uid: uid });
        return response.data;
    } catch (error) {
        throw new Error("Error deleting user:" + error);
    }
}

export const EditUserData = () => {
    const { action } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [userData, setUserData] = useState(null);
    const [valid, setValid] = useState(false)
    const [institutionsList, setInstitutionsList] = useState([]);
    useEffect(() => {
        const docRef = doc(db, "information_sources", 'institutions');
        const unsubscribe = onSnapshot(docRef, async (docSnap) => {
            const data = docSnap.data();
            console.log('data', data)
            // return data['institution_list'];
            setInstitutionsList(data['institution_list']);
        })
    }, [])
    const columns = [{
        dataField: 'id',
        text: 'ID',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '60px', textAlign: 'center', wordBreak: 'break-all' };
        }

    },
    {
        dataField: 'email',
        text: 'Email',

        filter: textFilter(),
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '250px', textAlign: 'center', wordBreak: 'break-all' };
        },
        editable: false

    }, {
        dataField: 'firstName',
        text: 'First Name',

        filter: textFilter(),
        sort: true,

    },
    {
        dataField: 'lastName',
        text: 'Last Name',

        filter: textFilter(),
        sort: true,

    }, {
        dataField: 'nickname',
        text: 'Nickname',
        sort: true,
        filter: textFilter(),

    },
    {
        dataField: 'atype',
        text: 'Role',
        sort: true,
        filter: textFilter(),
        editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
                //   console.log(`current editing row id: ${row.id}`);
                //   console.log(`current editing column: ${column.dataField}`);
                let dropDownList = []

                Object.values(allRoles).forEach(inst => {
                    dropDownList.push({
                        value: inst,
                        label: inst
                    })
                })
                return dropDownList;
            }
        }
    },
    {
        dataField: 'institution',
        text: 'Institution',
        sort: true,
        filter: textFilter(),
        editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
                //   console.log(`current editing row id: ${row.id}`);
                //   console.log(`current editing column: ${column.dataField}`);
                let dropDownList = []
                institutionsList.forEach(inst => {
                    dropDownList.push({
                        value: inst,
                        label: inst
                    })
                })
                return dropDownList;
            }
        }

    },
    {
        dataField: 'createdAtString',
        text: 'Created At',
        // filter: dateFilter(),
        sort: true,
        editable: false,
        sortFunc: (a, b, order, dataField) => {
            // Convert 'N/A' or other non-date values to a very old date for sorting purposes
            const dateA = a === 'N/A' ? new Date(0) : new Date(a);
            const dateB = b === 'N/A' ? new Date(0) : new Date(b);

            if (order === 'asc') {
                return dateB - dateA;
            }
            return dateA - dateB; // desc
        },

        headerStyle: (colum, colIndex) => {
            return { width: '130px', textAlign: 'center', wordBreak: 'break-word' };
        },
    },
    {
        dataField: 'lastSignedInString',
        text: 'Last Signed In',
        // filter: dateFilter(),
        sort: true,
        editable: false,
        sortFunc: (a, b, order, dataField) => {
            // Convert 'N/A' or other non-date values to a very old date for sorting purposes
            const dateA = a === 'N/A' ? new Date(0) : new Date(a);
            const dateB = b === 'N/A' ? new Date(0) : new Date(b);

            if (order === 'asc') {
                return dateB - dateA;
            }
            return dateA - dateB; // desc
        },
        headerStyle: (colum, colIndex) => {
            return { width: '130px', textAlign: 'center', wordBreak: 'break-word' };
        },
    },];

    const columns_active = [...columns, {
        dataField: "action",
        text: "Action",
        sort: false,
        formatter: actionFormatterActive,
        headerAttrs: { width: 60 },
        editable: false,
        // attrs: { width: 50, class: "EditRow" } 
    }]
    const columns_inactive = [...columns,
    {
        dataField: 'disabledAtString',
        text: 'Deactivated At',
        // filter: dateFilter(),
        sort: true,
        editable: false,
        sortFunc: (a, b, order, dataField) => {
            // console.log(new Date(b) - new Date(a))
            if (order === 'asc') {
                return new Date(b) - new Date(a);
            }
            return new Date(a) - new Date(b); // desc
        },
        headerStyle: (colum, colIndex) => {
            return { width: '130px', textAlign: 'center', wordBreak: 'break-word' };
        },
    }, {
        dataField: "action",
        text: "Action",
        sort: false,
        formatter: actionFormatterInactive,
        headerAttrs: { width: 60 },
        editable: false,
        // attrs: { width: 50, class: "EditRow" } 
    }]
    const rowStyle = (row, rowIndex) => {
        return { overflowWrap: 'break-word' };
    };
    function generateObjects(n) {
        let objects = [];
        for (let i = 0; i < n; i++) {
            let randomIndex = Math.floor(Math.random() * institutionsList.length);
            let randomIndex2 = Math.floor(Math.random() * Object.keys(allRoles).length);
            let obj = {
                id: i + 1,
                email: faker.internet.email(),
                firstName: faker.person.firstName(),
                lastName: faker.person.lastName(),
                nickname: generateRandomNicknames(false),
                institution: institutionsList[randomIndex],
                atype: Object.values(allRoles)[randomIndex2]
            };
            objects.push(obj);
        }
        return objects;
    }
    const [userRoster, setUserRoster] = useState(generateObjects(100))
    const [userRosterInitial, setUserRosterInitial] = useState(generateObjects(100))

    const [userRosterInactive, setUserRosterInactive] = useState(generateObjects(100))
    const [userRosterInitialInactive, setUserRosterInitialInactive] = useState(generateObjects(100))
    const [userDict, setUserDict] = useState({})

    useEffect(() => {
        console.log('valid', valid)
    }, [valid])
    function actionFormatterActive(cell, row, rowIndex, formatExtraData) {
        return (
            < div
                style={{
                    textAlign: "center",
                    cursor: "pointer",
                    // lineHeight: "normal"
                }}>

                <Button

                    //   disabled={botSubmit}
                    onClick={() => {
                        console.log('handleshow', row);
                        handleShowDisable();
                        setCurrentUserData(row);
                    }}
                    className="btn-sm"
                    variant='secondary'
                    data-tip='Deactivate this account.'
                >
                    {/* <AiOutlineDelete style={{ marginBottom: 2, fontSize: 16 }} /> */}
                    <BsFillSlashCircleFill style={{ marginBottom: 2, fontSize: 16 }} />
                    {/* {"Delete User"} */}
                </Button>
            </div>
        );
    }
    function actionFormatterInactive(cell, row, rowIndex, formatExtraData) {
        return (
            < div
                style={{
                    textAlign: "center",
                    cursor: "pointer",
                    // lineHeight: "normal"
                }}>
                <Button

                    //   disabled={botSubmit}
                    onClick={() => {
                        console.log('handleshow', row);
                        setUserStatus(row.userID, 'active');
                        setCurrentUserData(row);
                    }}
                    className="btn-sm"
                    variant='success'
                    data-tip='Recover this account.'
                >
                    <AiOutlineReload style={{ marginBottom: 2, fontSize: 16 }} />
                    {/* <AiOutlineDelete style={{ marginBottom: 2, fontSize: 16 }} /> */}
                    {/* <BsFillSlashCircleFill style={{ marginBottom: 2, fontSize: 16 }} /> */}
                    {/* {"Delete User"} */}
                </Button>
                <Button

                    //   disabled={botSubmit}
                    onClick={() => {
                        console.log('handleshow', row);
                        handleShow();
                        setCurrentUserData(row);
                    }}
                    className="btn-sm"
                    variant='danger'
                    data-tip='Delete this account.'
                >
                    {/* <AiOutlineReload style={{ marginBottom: 2, fontSize: 16 }} /> */}
                    <AiOutlineDelete style={{ marginBottom: 2, fontSize: 16 }} />
                    {/* <BsFillSlashCircleFill style={{ marginBottom: 2, fontSize: 16 }} /> */}
                    {/* {"Delete User"} */}
                </Button>
            </div>
        );
    }
    useEffect(() => {
        const q = query(collection(db, "Users"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const users = {};
            const remoteRoster = [];
            const remoteRosterInactive = [];

            let index = 0
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const docID = doc.id;
                let { userID, email, emailAddress, status,
                    encrypted, atype, nickname,
                    institution, createdAt, lastSignedIn } = data;
                if (docID == '9lpx1o4E8dXclmu8NqfFUPBVsfg2') {
                    console.log('found data', data)
                }
                // console.log('docID', docID, 'data', data)
                let firstName = data.firstName
                let lastName = data.lastName
                if (encrypted == true) {
                    firstName = base64Decode(data.firstName)
                    lastName = base64Decode(data.lastName)
                }
                if (!status) {
                    status = 'active'
                }
                if (!email) {
                    email = emailAddress
                }

                let createdAtString = 'N/A'
                if (createdAt) {
                    // createdAt = 'N/A'
                    createdAtString = createdAt.toDate().toDateString()
                    let createdTime = createdAt.toDate().toLocaleTimeString()
                    createdAtString = createdAtString + ', ' + createdTime
                }

                let lastSignedInString = 'N/A'
                if (lastSignedIn) {
                    lastSignedInString = lastSignedIn.toDate().toDateString()
                    let signedInTime = lastSignedIn.toDate().toLocaleTimeString()
                    lastSignedInString = lastSignedInString + ', ' + signedInTime
                }


                const newItem = {
                    id: index + 1,
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    nickname: nickname ? nickname : 'N/A',
                    institution: institution,
                    atype: allRolesCompat[atype],
                    userID: userID,
                    encrypted: encrypted,
                    createdAt,
                    lastSignedIn,
                    createdAtString,
                    lastSignedInString
                }

                if (status == 'active') {
                    remoteRoster.push(
                        newItem
                    )
                }
                else if (status == 'disabled') {
                    const disabledAt = data.disabledAt;
                    let disabledAtString = 'N/A'
                    if (disabledAt) {
                        disabledAtString = disabledAt.toDate().toDateString()
                        let disabledTime = disabledAt.toDate().toLocaleTimeString()
                        disabledAtString = disabledAtString + ', ' + disabledTime
                    }

                    remoteRosterInactive.push({ ...newItem, disabledAt, disabledAtString });
                }

                users[userID] = newItem;

                index += 1
                // console.log(`User Data: `, data);

            });
            setUserDict({ ...users })
            console.log('index', index)
            setUserRoster(remoteRoster)
            setUserRosterInitial(JSON.parse(JSON.stringify(remoteRoster)))
            setUserRosterInactive(remoteRosterInactive)
            setUserRosterInitialInactive(JSON.parse(JSON.stringify(remoteRosterInactive)))

        });
    }, [])
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };
    const [showDisable, setShowDisable] = useState(false);
    const [currentUserData, setCurrentUserData] = useState(null);
    const handleShowDisable = () => {
        setShowDisable(true);
    };
    const handleCloseDisable = () => {
        setShowDisable(false);
    };
    const [showSave, setShowSave] = useState(false);
    const handleShowSave = () => {
        setShowSave(true);
    };
    const handleCloseSave = () => {
        setShowSave(false);
    };
    const deleteUser = async (userID) => {
        handleClose();

        if (userID) {
            let docSnapshot = await getDoc(doc(db, "Users", userID));
            let userData = {}

            if (docSnapshot.data()) {
                userData = docSnapshot.data()
                userData = { ...userData, deletedAt: serverTimestamp(), status: 'deleted' }
                console.log('deleted userData', userData)

                // Move the user data to the 'user_recently_deleted' collection
                await setDoc(doc(db, "user_recently_deleted", userID), userData, { merge: true })

                // Optionally delete the user from the original "Users" collection (uncomment if needed)
                await deleteDoc(doc(db, "Users", userID))

                // Now, call the server to actually delete the user
                try {
                    const serverResponse = await deleteUserInServer(userID);
                    console.log(serverResponse);
                } catch (error) {
                    console.error(error);
                }

                setCurrentUserData(null);
            }
        }
    }

    const setUserStatus = async (userID, status = 'active') => {
        handleClose();

        if (userID) {
            let docSnapshot = await getDoc(doc(db, "Users", userID));
            let userData = {}
            if (docSnapshot.data()) {
                userData = docSnapshot.data();

                if (status === 'disabled') {
                    await disableUserInServer(userID);
                    userData = {
                        ...userData,
                        disabledAt: serverTimestamp(),
                        status: status
                    }
                } else if (status === 'active') {
                    await enableUserInServer(userID);
                    userData = {
                        ...userData,
                        status: status
                    }
                }

                await setDoc(doc(db, "Users", userID), userData, { merge: true })
                setCurrentUserData(null);
                handleCloseDisable();
            }
        }
    }
    const editUser = async (userID) => {
        if (userID && currentUserData) {
            // console.log('currentUserData', currentUserData);
            console.log('currentUserData', currentUserData);

            const dataField = currentUserData.column.dataField;
            let data = {}

            if (dataField == 'firstName' || dataField == 'lastName') {
                if (currentUserData.encrypted == true) {
                    data = { [dataField]: base64Encode(currentUserData.newValue) }
                }
                else {
                    data = { [dataField]: currentUserData.newValue }
                }
            }
            else {
                if (dataField == 'atype') {
                    let oldRole = allRolesReverse[currentUserData.oldValue]
                    let newRole = allRolesReverse[currentUserData.newValue]
                    let { encrypted, firstName, lastName } = currentUserData;
                    let finalFirstName = firstName;
                    let finalLastName = lastName;
                    let finalEncrypted = false;
                    data = { [dataField]: newRole }
                    if (!isStudentOrMentor(oldRole) && isStudentOrMentor(newRole)) { //high->student => encrypt
                        finalEncrypted = true;

                        if (currentUserData.encrypted == false) {
                            finalFirstName = base64Encode(firstName);
                            finalLastName = base64Encode(lastName);
                        }
                    }
                    // else if (isStudentOrMentor(oldRole) && !isStudentOrMentor(newRole)) { //student->high => decrypt
                    //     if (currentUserData.encrypted == true) {
                    //         finalEncrypted = false;
                    //         // finalFirstName = base64Decode(firstName);
                    //         // finalLastName = base64Decode(lastName);
                    //     }
                    //     finalEncrypted = false;
                    // }


                    data = {
                        'atype': newRole,
                        firstName: finalFirstName,
                        lastName: finalLastName,
                        encrypted: finalEncrypted
                    }
                    //

                    // if (isStudentOrMentor(newRole) || newRole == 'faculty') {
                    //     data.encrypted = true;
                    // }
                    // else {
                    //     data.encrypted = false;
                    // }


                }
                else {
                    data = { [dataField]: currentUserData.newValue }
                }
            }
            data.email = userDict[userID].email;
            // data.encrypted = true;


            console.log('data to edit', data)

            await setDoc(doc(db, "Users", userID), data, { merge: true })

        }
        handleCloseSave();

    }
    useEffect(() => {
        console.log('userRoster', userRoster);
        ReactTooltip.rebuild();
    }, [userRoster])
    return <div>
        <NavBar setUserDataExternal={setUserData} />
        <RoleValidationComponent requiredRoles={['hub-lead-admin', 'hub-lead']}
            redirect={true}
            setValidExternal={setValid} />
        <ReactTooltip />
        <div style={{ textAlign: 'center', margin: 5 }}>
            <h2>AccountManagement Dashboard</h2>

            <u style={{ fontSize: 17 }}>Instructions: Click any field to start editing.</u>

        </div>
        <details open className='my-details' style={{ borderColor: '#00C853' }}>
            <summary style={{ fontSize: 20, fontWeight: 'bold' }}>Active Users ({userRoster && userRoster.length})


            </summary>
            {userRoster && <div><h6>Total Active Users: {userRoster.length}</h6></div>}

            <div>
                <h6><i>Note: Email is <u>NOT</u> editable.</i></h6>
            </div>

            <BootstrapTable
                bootstrap4
                keyField='id'
                data={userRoster}
                columns={columns_active}
                filter={filterFactory()}
                pagination={paginationFactory()}
                rowStyle={rowStyle}
                cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                        // alert('beforeSaveCell')
                        if (oldValue != newValue) {
                            handleShowSave();
                            row.oldValue = oldValue;
                            row.newValue = newValue;
                            row.column = column;
                            setCurrentUserData(row)
                        }

                        // setUserRoster([...userRosterInitial])
                    },
                    // afterSaveCell: (oldValue, newValue, row, column) => { console.log('row', row, 'column', column) }
                })} />
        </details>
        <details className='my-details' style={{ borderColor: 'gray' }}>
            <summary style={{ fontSize: 20, fontWeight: 'bold' }}>Total Inactive Users ({userRosterInactive && userRosterInactive.length})


            </summary>
            {userRosterInactive && <div><h6>Total Inactive (Disabled) Users: {userRosterInactive.length}</h6></div>}

            <div>
                <h6><i>Note: You have to recover the account before you can edit.</i></h6>
            </div>

            <BootstrapTable
                bootstrap4
                keyField='id'
                data={userRosterInactive}
                columns={columns_inactive}
                filter={filterFactory()}
                pagination={paginationFactory()}
                rowStyle={rowStyle}
            />
        </details>
        <Modal
            size="lg"
            show={showDisable}
            onHide={handleCloseDisable}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Account Deactivation Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Are you sure you would like to deactivate this account?  </h5>
                {currentUserData &&
                    <div>
                        <h6>Email: {currentUserData.email}</h6>
                        <h6>Name: {currentUserData.firstName} - {currentUserData.lastName}</h6>
                        <h6>Nickname: {currentUserData.nickname}</h6>
                        <p><strong>Note:</strong> This is temporary, the account can be restored from the table below.</p>
                    </div>
                }
                {/* <h6>Creator Email: {creatorEmail}</h6> */}
                {/* <i>Note: You can recover this by clicking on <Button size='sm' className='mx-1' variant="danger" disabled>
                    View Recently Deleted Forms
                </Button></i> on the main page. */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="btn button-fill-theme" onClick={() => {
                    if (currentUserData) {
                        setUserStatus(currentUserData.userID, 'disabled')
                    }
                }}>Yes, I would like to deactivate this account.</Button>
            </Modal.Footer>
        </Modal>

        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>AccountDeletion Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Are you sure you would like to delete this account?  </h5>
                {currentUserData &&
                    <div>
                        <h6>Email: {currentUserData.email}</h6>
                        <h6>Name: {currentUserData.firstName} - {currentUserData.lastName}</h6>
                        <h6>Nickname: {currentUserData.nickname}</h6>
                    </div>}
                {/* <h6>Creator Email: {creatorEmail}</h6> */}
                {/* <i>Note: You can recover this by clicking on <Button size='sm' className='mx-1' variant="danger" disabled>
                    View Recently Deleted Forms
                </Button></i> on the main page. */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    No, I would like to keep this account.
                </Button>
                <Button variant="danger" onClick={() => {
                    if (currentUserData) {
                        deleteUser(currentUserData.userID)
                    }
                }}>Yes, I would like to delete this account.</Button>
            </Modal.Footer>
        </Modal>
        <Modal
            size="lg"
            show={showSave}
            onHide={handleCloseSave}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Value Edit Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Would you like to save the change?  </h5>
                {currentUserData &&
                    <div>
                        <h6>Email: {currentUserData.email}</h6>
                        {/* <h6>Name: {currentUserData.firstName} - {currentUserData.lastName}</h6> */}
                        {/* <h6>Nickname: {currentUserData.nickname}</h6> */}
                        <p>Old Value: <i>{currentUserData.oldValue}</i></p>
                        <p>New Value: <b>{currentUserData.newValue}</b></p>

                    </div>}
                {/* <h6>Creator Email: {creatorEmail}</h6> */}
                {/* <i>Note: You can recover this by clicking on <Button size='sm' className='mx-1' variant="danger" disabled>
                    View Recently Deleted Forms
                </Button></i> on the main page. */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setUserRoster([...userRosterInitial])
                    handleCloseSave();
                }}>
                    No
                </Button>
                <Button variant="danger" onClick={() => {
                    if (currentUserData) {
                        editUser(currentUserData.userID);
                    }
                }}>Yes, proceed.</Button>
            </Modal.Footer>
        </Modal>
    </div >
}