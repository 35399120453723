import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { ConvertRGBtoRGBA, random_rgb } from "../Automatic Forms/Utils";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const data = {
  labels: ["Thing 1", "Thing 2", "Thing 3", "Thing 4", "Thing 5", "Thing 6"],
  datasets: [
    {
      label: "# of Votes",
      data: [2, 15, 30, 5, 2, 3],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 1,
    },
  ],
};

export function RadarChart({ chartData, chartRef }) {
  const [displayChartData, setDisplayChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
    maintainAspectRatio: false,
  });

  useEffect(() => {
    if (chartData) {
      console.log("Radar Chart Data: ", chartData);
      let rawData = chartData.data;
      let { color, alpha } = chartData;
      let borderColor = ConvertRGBtoRGBA(random_rgb(), 1);
      let backgroundColor = ConvertRGBtoRGBA(random_rgb(), 0.4);

      if (!alpha) {
        alpha = 0.4;
      }
      if (color) {
        borderColor = ConvertRGBtoRGBA(color, 1);
        backgroundColor = ConvertRGBtoRGBA(color, 0.4);
      }

      if (rawData) {
        // Filter out any undefined or null values
        const filteredData = Object.entries(rawData).filter(
          ([key, value]) => value !== undefined && value !== null
        );
        const labels = filteredData.map((item) => item[0]);
        const dataValues = filteredData.map((item) => item[1]);

        const processedData = {
          labels,
          datasets: [
            {
              label: chartData.title,
              data: dataValues,
              borderColor,
              backgroundColor,
              borderWidth: 1,
            },
          ],
        };

        console.log("Radar Processed Chart Data", processedData);
        setDisplayChartData(processedData);
        setChartOptions({
          ...chartOptions,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: chartData.title,
            },
          },
        });
      }
    }
  }, [chartData]);

  return (
    <div>
      {displayChartData && (
        <Radar
          height={chartData.dimension ? chartData.dimension.height : 400}
          width={chartData.dimension ? chartData.dimension.width : 400}
          options={chartOptions}
          data={displayChartData}
          ref={chartRef}
          id="mainChart"
        />
      )}
    </div>
  );
}
