import React, { useEffect, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Bars } from 'react-loading-icons';
import theme from '../Theme/theme';
const { GoogleGenerativeAI } = require("@google/generative-ai");



const size = [1000, 500];
const options = {
  rotations: 0,
  rotationAngles: [0, 0],
  fontSizes: [25, 60], 
};
const exampleWords = [
  {
    text: 'told',
    value: 64,
  },
  {
    text: 'mistake',
    value: 11,
  },
  {
    text: 'thought',
    value: 16,
  },
  {
    text: 'bad',
    value: 17,
  },
]
export const WordCloud = ({chartData}) => {
    const [words, setWords] = useState([]);
    console.log('chartData (wordCloud)', chartData)
    const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
    async function getWordsFromGemini(chartData) {

      const prompt = `process the data and generate a list of 10-20 simple keys (1 word) using the following data: ${JSON.stringify(chartData)}. 
      Determine the strength of each word on a scale of 1-5. Make sure the words are at least related to the questions ${chartData.title}. Don't output filler words.
      Output it in json format only! For example ${JSON.stringify(exampleWords)}. No markdown or fencing needed.`;
      
      console.log('calling prompt', prompt);
      
      const result = await model.generateContent(prompt);
      const response = await result.response;
      const text = await response.text(); // Make sure to await this if it's a promise
      
      console.log('text', text);
      
      try {
        const jsonOutput = JSON.parse(text);
        console.log('jsonOutput', jsonOutput);
        setWords(jsonOutput); // Use this parsed JSON as needed
      } catch (error) {
        console.error('Failed to parse JSON:', error);
      }
    }
    
    
    useEffect(() => {
        getWordsFromGemini(chartData);
    }, [chartData]);
    return (
        <div className='text-center'>
          <h3>Word Cloud</h3>
          {chartData && <h4>Question: {chartData.title}</h4>}
          <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: size[0], height: size[1], margin: '0 auto' }}
      >
        {words.length === 0 ? (
          <Bars
            stroke="#000"
            fill={`${theme.highlightColor}`}
            speed={1}
            width={300}
            height={300}
          />
        ) : (
          <ReactWordcloud words={words} 
          size={size} 
          options={options}
           />
        )}
      </div>
        </div>
    );
}
