import React, { useEffect, useState } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Input, RadioGroup } from "../Components";
import { Link } from "react-router-dom";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  deleteUser,
  signOut
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { db } from "../Firebase/firebasedb";
import { doc, setDoc, getDoc, onSnapshot, query, collection, serverTimestamp } from "firebase/firestore";
// import { institutionsList } from "../Fixed Sources/institutions";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { atypeOptions, decodeInviteCode, encodeInviteCode, equivalentRoleDict } from "../Fixed Sources/accountTypes";
import { useSearchParams } from "react-router-dom";
import { faker } from '@faker-js/faker';
import { AiTwotoneLock, AiFillQuestionCircle, AiFillCloseCircle, AiOutlineInfoCircle, AiOutlineLock } from "react-icons/ai";
import ReactTooltip from 'react-tooltip';
import theme from "../Theme/theme";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { CustomMenu, FakeNameDropDown } from "../Automatic Forms/Utils";
import { Row } from "react-bootstrap";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref('password')], 'Passwords must match'),

  password: Yup.string()
    .required("Required")
    // .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one lowercase, one uppercase, one number and one special case character."
    ),
  atype: Yup.string().typeError("Please enter your account type!"),
  email: Yup.string().required("Required").email("Email is invalid"),
  institution: Yup.string().required("Institution Required"),
  nickname: Yup.string().required('Nickname Required')

});


const InstitutionDropDown = ({ errors, setValue }) => {
  const [institutionsList, setInstitutionsList] = useState([]);
  useEffect(() => {
    const docRef = doc(db, "information_sources", 'institutions');
    onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        // console.log(data)
        setInstitutionsList(data['institution_list'])
      } else {

      }
    });
  }, [])
  // const { handleSubmit, reset, register, setValue, formState: { errors } } = methods;

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant={errors['institution'] && !currentEventKey ? 'outline-danger' : 'btn btn-outline-theme'}
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>{children}
      &#x25bc;</Button>
  ));
  const [currentEventKey, setCurrentEventKey] = useState('')
  return <div>
    <Dropdown className='col-md-6 mt-2 '
      onSelect={(eventKey, event) => {
        // console.log('setValue', setValue)
        setValue('institution', eventKey);
        setCurrentEventKey(eventKey)
      }}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        Select Your Institution: <b>{currentEventKey}</b>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} >
        {institutionsList.map(institute => {
          return <Dropdown.Item eventKey={institute} active={institute == currentEventKey}>{institute}</Dropdown.Item>
        })}
      </Dropdown.Menu>
    </Dropdown>
    <div className="is-invalid">
      {/* <div className="invalid-feedback">hello22</div> */}

    </div>
    <div className="invalid-feedback mb-2">{errors['institution'] && !currentEventKey ? errors['institution'].message : ''}</div>

  </div>
}

const RegisterForm = ({ key, setKey = null }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [user, setUser] = useState(null);

  const [redirectDestination, setRedirectDestination] =
    useState("/");
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
      }
    });
    return () => unsubscribe();
  }, [auth]);
  useEffect(() => {
    if (user && key == 'register') {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          // setUserData(data);
          setRedirectDestination("/");
          setShouldRedirect(true);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          signOut(auth)
            .then(() => {
              // Sign-out successful.
              console.log("Signed out successfully!");
              // deleteAuthUser();

            })
            .catch((error) => {
              console.log(error);
            });
          // setRedirectDestination("/");
        }
      });
    }
  }, [user]);

  const formOptions = { resolver: yupResolver(validationSchema) };
  const methods = useForm(formOptions);
  const { handleSubmit, reset, register, setValue, formState: { errors }, watch, getValues } = methods;
  const atype = watch('atype')

  useEffect(() => {
    if (atype && !atype.includes('student')) {
      console.log('here')
      setValue('nickname', 'No Nickname')
    }
    else {
      setValue('nickname', '')
    }
  }, [atype])

  const handleRegistration = (data, e) => {
    e.preventDefault();

    if (data.atype.includes('student')) {
      data.encrypted = true;
      data.firstName = base64_encode(data.firstName);
      data.lastName = base64_encode(data.lastName);
    } else {
      data.encrypted = false;
    }
    // Intentionally adding an invalid field to cause a Firestore write error
    // data.invalidField = undefined;

    // Step 1: Create authentication account
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Create a new object without password and confirmPassword
        const userData = {
          ...data,
          userID: userCredential.user.uid,
          createdAt: serverTimestamp(),
          platform: 'web'
        };
        delete userData.password;
        delete userData.confirmPassword;

        // Step 2: Write userData to Firestore
        return writeToFirebase(userData);
      })
      .then(() => {
        console.log("User authentication account created and data written to Firebase!");
        setRedirectDestination("/");
        setShouldRedirect(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(error.message || error);

        // Optional: Delete the authentication account if Firestore write fails
        if (data.userID) {
          deleteAuthUser();
        }
      });
  };


  function writeToFirebase(data) {
    return setDoc(doc(db, "Users", data.userID), { ...data });
  }

  function deleteAuthUser() {
    const user = auth.currentUser;
    if (user) {
      deleteUser(user)
        .then(() => {
          console.log("User authentication account deleted due to data write failure.");
          window.location.reload();

        })
        .catch((error) => {
          console.error("Failed to delete user authentication account:", error);
        });
    }
  }

  register('nickname', { required: true })

  console.log(errors)


  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const inviteCode = searchParams.get('inviteCode')
    if (inviteCode && setKey) {
      setKey('register')
    }
  }, [searchParams, setKey])
  const inviteCode = searchParams.get('inviteCode')
  let aTypeOptionsFiltered = []
  let filteredRoles = ['student', 'student-mentor']
  if (inviteCode) {
    const inviteRoles = decodeInviteCode(inviteCode).split('||')
    filteredRoles = inviteRoles.length > 0 ? inviteRoles : filteredRoles
    filteredRoles = filteredRoles.map(role => equivalentRoleDict[role])
    console.log('inviteCode', inviteCode, filteredRoles)
  }
  // aTypeOptionsFiltered = atypeOptions
  aTypeOptionsFiltered = atypeOptions.filter(atype => {
    if (filteredRoles.includes(atype.value)) {
      // setValue('atype', atype.value)
      return atype
    }
  })
  const safeIconFirstName = <AiTwotoneLock style={{
    marginLeft: '2px', marginBottom: '3px', color: 'green',
    fontSize: '15px'
  }} data-tip={`Real names will be encrypted safely in the system.`} />
  return (
    <div className="container">

      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="card m-3 border-light">
          {/* <header class="card-header">
            <Link class="float-end btn btn-outline-primary" to="/login">
              Log in
            </Link>
            <h4 class="card-title mt-2">Sign up</h4>
          </header> */}
          <div className="card-body">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(handleRegistration)}>
                <div className="row">
                  <Input
                    name="firstName"
                    label="First Name"
                    className="mb-3 col-xl-6"
                    required={true}
                    additionalIcons={[safeIconFirstName]}

                  />
                  <Input
                    name="lastName"
                    label="Last Name"
                    className="mb-3 col-xl-6"
                    required={true}
                    additionalIcons={[safeIconFirstName]}


                  />
                </div>
                <div className="row">
                  <Input name="email" label="Email" className="mb-3 col-12"
                    required={true}
                  />
                </div>
                <div className="row">
                  <Input
                    name="password"
                    label="Password"
                    className="mb-3 col-12"
                    type="password"
                    required={true}
                    instructions={"Password must contain at least 8 characters, one lowercase, one uppercase, one number and one special character."}
                  />
                </div>
                <Row>
                  <Input
                    name="confirmPassword"
                    label="Confirm Password"
                    className="mb-3 col-12"
                    type="password"
                    required={true}

                  />
                </Row>
                <div className="row">
                  <RadioGroup
                    label="Account Type"
                    name="atype"
                    options={aTypeOptionsFiltered}
                    instructions={"If you are signing up for other account types, please contact your portal admin for more information."}

                  />
                </div>
                {atype && atype.includes('student') && <FakeNameDropDown errors={errors} setValue={setValue} />}
                <InstitutionDropDown errors={errors} setValue={setValue} />
                <div className="row d-flex justify-content-center mb-3">
                  <div className="form-group">
                    <Button type="submit" size='lg' variant="btn button-fill-theme">
                      Register
                    </Button>
                    {/* <button
                      type="button"
                      onClick={() => reset()}
                      className="btn btn-secondary"
                    >
                      Reset
                    </button> */}
                  </div>
                </div>
                <small className="text-muted">
                  By clicking the 'Register' button, you confirm that you accept
                  our Terms of use and Privacy Policy.
                </small>
              </form>
            </FormProvider>
          </div>
          <div className="border-top card-body text-center">
            <div className="mt-3 text-center">
              Already have an account?
              <Button variant="btn btn-outline-theme" style={{ marginLeft: 5 }}
                onClick={() => {
                  if (setKey)
                    setKey('login')
                }
                } className="ml-2">
                Log in
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterForm;
