import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from 'firebase/functions';
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBW0dKgcQT_wOiuefSEzhl0opGFhxZVIDo",
    authDomain: "includes-5bac5.firebaseapp.com",
    projectId: "includes-5bac5",
    storageBucket: "includes-5bac5.appspot.com",
    messagingSenderId: "125756290283",
    appId: "1:125756290283:web:a2a76c2f104754df67baae",
    measurementId: "G-Q1Z6QY4RN2"
};

export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);
const functions = getFunctions(app);

export const getUserNicknamesFunction = httpsCallable(functions, 'getUserNicknamesCallable')
