import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ChartDashboard } from "./ChartDashboard";
import { NavBar } from "../Components/NavBar";
import { useState } from "react";
import { useEffect } from "react";
import { ChartEdit } from "./ChartEdit";
import { SpinningCircles } from "react-loading-icons";
import theme from "../Theme/theme";
import { ChartRenderingUser } from "./ChartRenderingUser";
import { ChartManagementDashboard } from "./ChartManagementDashboard";
import { ChartDashboardStudent } from "./ChartDashboardStudent";
import { isCampusLead, isHubLead, isPortalAdmin, isStudentOrMentor } from "../Fixed Sources/accountTypes";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { ErrorPage } from "../Forms/ErrorPage";

const RenderComponent = ({ userData }) => {
    let { chartID, action } = useParams();
    const analytics = getAnalytics();

    useEffect(() => {
        if (userData) {
            const { atype, userID, institution } = userData;
            const role = atype;
            if (isPortalAdmin(role) || isHubLead(role)) {
                if (!chartID || chartID == 'dashboard') {
                    console.log('document.title = "SOAR - Admin Chart Dashboard"')
                    document.title = "SOAR - Admin Chart Dashboard";

                    logEvent(analytics, 'chart_management_dashboard', { timeStamp: new Date(), userID, atype, institution });
                }
                else {
                    if (chartID) {
                        if (chartID == 'create') {
                            document.title = "SOAR - Chart Creation";
                            logEvent(analytics, 'chart_create', { timeStamp: new Date(), userID, atype, institution });
                        }
                        if (action) {
                            if (action == 'edit') {
                                document.title = "SOAR - Chart Edit";

                                logEvent(analytics, 'chart_edit', { timeStamp: new Date(), chartID, userID, atype, institution });
                            }
                        }
                        else {
                            document.title = "SOAR - Individual Chart Preview";

                            logEvent(analytics, 'chart_rendering', { timeStamp: new Date(), chartID, userID, atype, institution });
                        }
                    }
                }
            }
            // else if (isHubLead(role) || isCampusLead(role)) {
            //     if (chartID == 'dashboard') {
            //         document.title = "SOAR - Admin Chart Dashboard";

            //         logEvent(analytics, 'chart_management_dashboard', { timeStamp: new Date(), userID, atype, institution });
            //     }
            //     else if (!chartID) {
            //         document.title = "SOAR - Individual Chart Dashboard";

            //         logEvent(analytics, 'chart_personal_dashboard', { timeStamp: new Date(), userID, atype, institution });
            //     }
            //     else {
            //         if (chartID) {
            //             if (chartID == 'create') {
            //                 document.title = "SOAR - Chart Creation";

            //                 logEvent(analytics, 'chart_create', { timeStamp: new Date(), userID, atype, institution });

            //             }
            //             if (action) {
            //                 if (action == 'edit') {
            //                     document.title = "SOAR - Chart Edit";

            //                     logEvent(analytics, 'chart_edit', { timeStamp: new Date(), chartID, userID, atype, institution });
            //                 }
            //             }
            //             else {
            //                 document.title = "SOAR - Individual Chart Preview";


            //                 logEvent(analytics, 'chart_rendering', { timeStamp: new Date(), chartID, userID, atype, institution });
            //             }
            //         }
            //     }
            // }
            else {

                if (!chartID) {
                    document.title = "SOAR - Individual Chart Dashboard";

                    logEvent(analytics, 'chart_personal_dashboard', { timeStamp: new Date(), userID, atype, institution });
                }
                else {
                    if (chartID) {
                        if (chartID == 'dashboard') {
                            document.title = "SOAR - Individual Chart Dashboard";

                            logEvent(analytics, 'chart_personal_dashboard', { timeStamp: new Date(), userID, atype, institution });
                        }
                        if (!action) {
                            document.title = "SOAR - Individual Chart Preview";

                            logEvent(analytics, 'chart_rendering', { timeStamp: new Date(), chartID, userID, atype, institution });
                        }
                    }
                }
            }
        }
    }, [chartID, action, userData]); // Dependencies
    if (userData) {
        // console.log('userData', userData)
        console.log('Role: ', userData.atype)
        const role = userData.atype;
        if (isPortalAdmin(role) || isHubLead(role)) {
            if (!chartID || chartID == 'dashboard') {
                return <ChartManagementDashboard userData={userData} />
            }

            if (chartID) {
                if (chartID == 'create') {
                    return <ChartDashboard userData={userData} />
                }
                if (action) {

                    if (action == 'edit') {
                        return <ChartEdit userData={userData} />
                    }

                }
                else {

                    return <ChartRenderingUser userData={userData} />

                }
            }

        }
        else {
            if (!chartID) {
                return <ChartDashboardStudent userData={userData} />
            }
            else {
                if (chartID) {
                    if (chartID == 'dashboard') {
                        return <ChartDashboardStudent userData={userData} />
                    }
                    else if (chartID == 'create') {
                        return <ErrorPage error={500} />
                    }
                    if (action) {

                        if (action == 'edit') {
                            return <ErrorPage error={500} />
                        }

                    }
                    else {
                        return <ChartRenderingUser userData={userData} />
                    }
                }
            }
        }
        // else if (isCampusLead(role)) {
        //     if (chartID == 'dashboard') {
        //         return <ChartManagementDashboard userData={userData} />
        //     }
        //     else if (!chartID) {
        //         return <ChartDashboardStudent userData={userData} />
        //     }
        //     else {
        //         if (chartID) {
        //             if (action) {
        //                 if (chartID == 'create') {
        //                     return <ChartDashboard userData={userData} />
        //                 }
        //                 if (action == 'edit') {
        //                     return <ChartEdit userData={userData} />
        //                 }

        //             }
        //             else {

        //                 return <ChartRenderingUser userData={userData} />

        //             }
        //         }
        //     }
        // }
        // else if (role == 'faculty') {
        //     if (chartID == 'dashboard') {
        //         return <ChartDashboardStudent userData={userData} />
        //     }
        //     else if (!chartID) {
        //         return <ChartDashboardStudent userData={userData} />
        //     }
        //     else {
        //         if (chartID) {
        //             if (!action) {
        //                 return <ChartRenderingUser userData={userData} />
        //             }
        //         }
        //     }
        // }
        // else {
        //     if (chartID == 'dashboard') {
        //         return <ChartDashboardStudent userData={userData} />
        //     }
        //     else if (!chartID) {
        //         return <ChartDashboardStudent userData={userData} />
        //     }
        //     else {
        //         if (chartID) {
        //             if (!action) {
        //                 return <ChartRenderingUser userData={userData} />
        //             }
        //         }
        //     }
        // }
    }
    return <><div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SpinningCircles stroke="#000"
            fill={`${theme.highlightColor}`}
            speed={1} width={300} height={300} />
    </div></>

}
const DefaultCharts = () => {
    const [userData, setUserData] = useState(null);
    return (
        <>
            <NavBar setUserDataExternal={setUserData} />
            <RenderComponent userData={userData} />
        </>
    );
};
export default DefaultCharts;