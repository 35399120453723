import {
  Navbar,
  // Nav,
  // NavDropdown,
  Container,
  Nav,
  Button,
  // Form,
  // FormControl,
  // Button,
} from "react-bootstrap";
import soar from "../img/soar.png";
import theme from "../Theme/theme";
import styled from "styled-components";
const PreviewICon = styled.div`
    &:after {
        content: " Preview";
        font-size: 0.8em;
        vertical-align: super;
        color: ${theme.highlightColor};
    }
`;
const Header = () => {
  return (
    <Navbar bg="light" expand="llg" className="py-0 fs-6">
      <Container fluid>
        <Navbar.Brand href="/" className="fs-6">
          <img
            /* src="https://tapdintostem.org/wp-content/uploads/2021/12/12142021TAPDINTOSTEM-LOGO-Low-Res-336799.jpg" */
            src={soar}
            width="60"
            height="30"
            className="d-inline-block align-top"
            alt="SOAR logo"
          />
          Surmounting Obstacles for Academic Resilience
        </Navbar.Brand>
        <Nav>
          <Navbar.Text>
            <Nav.Link href='https://beta.soar-ai.com' target="_blank" rel="noopener noreferrer">
              <PreviewICon><Button variant='btn button-outline-theme' size="sm">NEW VERSION!</Button></PreviewICon></Nav.Link>
          </Navbar.Text>
        </Nav>
        {/* <Form className="d-flex"> */}
        {/* <FormControl */}
        {/*   type="search" */}
        {/*   placeholder="Search" */}
        {/*   className="me-2" */}
        {/*   aria-label="Search" */}
        {/* /> */}
        {/* <Button variant="outline-success">Search</Button> */}
        {/* </Form> */}
      </Container>
    </Navbar>
  );
};

export default Header;
