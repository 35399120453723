import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
// import Button from 'react-bootstrap/Button';
import { Modal, Button, ListGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import { Alert } from "react-bootstrap";
import { db } from "../Firebase/firebasedb";
import { doc, getDoc, setDoc, onSnapshot, orderBy, serverTimestamp, query, collection } from "firebase/firestore";
import AvailableForms from "./AvailableForms";
import { NavBar } from "./NavBar";
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { allRoles, allRolesCompat, allRolesRegular, atypeOptions, getFirebaseDocumentID, isPortalAdmin, isStudentOrMentor } from "../Fixed Sources/accountTypes";
import { AiFillQuestionCircle, AiFillCloseCircle } from "react-icons/ai";
import ReactTooltip from 'react-tooltip';
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import theme from "../Theme/theme";
import { FormOrderDnD } from "../Automatic Forms/FormOrderDnD";
import { readInstitutions, writeInstitutions } from "../Fixed Sources/institutions";
import { Textarea } from "./Textarea";
// import { Input } from "./Input";
import Card from 'react-bootstrap/Card';
import { deleteFormsPermanent, formatDate, getDifferenceInDays, restoreForm } from "../Automatic Forms/Utils";
import { CustomMenu } from "../Automatic Forms/Utils";
import { base64Decode, base64Encode } from "@firebase/util";
import { AiTwotoneUnlock, AiOutlineUnlock, AiTwotoneLock } from "react-icons/ai";
import { getAnalytics, logEvent } from "firebase/analytics";

import './DashboardStyle.css'
import styled from 'styled-components';
import { FormTable, FormTableManager } from "./FormTable";
const BetaIcon = styled.div`
    &:after {
        content: " BETA";
        font-size: 0.8em;
        vertical-align: super;
        color: red;
    }
`;
function EditUserRole({ userData }) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [userRoster, setUserRoster] = useState({})
  const [userEmailList, setUserEmailList] = useState([])

  const [currentUser, setCurrentUser] = useState(null)
  const [currentUserInfo, setCurrentUserInfo] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const toggleEdit = () => {
    if (isEditing) {
      writeToFirebase();
    }
    setIsEditing(!isEditing);
  }
  const handleCancelEdit = () => {
    setIsEditing(false);
    setCurrentUserInfo(userRoster[currentUser])
  }
  const writeToFirebase = () => {
    console.log('currentUserInfo', currentUserInfo)
    setDoc(doc(db, "Users", currentUser), currentUserInfo, { merge: true })
      .then(() => {
        handleShowSave('User Info Saved Successfully!')
      }).catch(err => {
        console.log(err)
        alert(err)
        handleShowSave('User Info Failed. Please Try Again.')

      });

  }

  useEffect(() => {
    const q = query(collection(db, "Users"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const users = {};
      const emailList = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        users[data.userID] = data;
        if (data.email)
          emailList.push(data.email);
        // console.log(`User Data: `, data);

      });
      emailList.sort()
      setUserRoster({ ...users })
      setUserEmailList(emailList)
    });
  }, [])
  useEffect(() => {
    if (currentUser && userRoster) {
      const userData = userRoster[currentUser]
      setCurrentUserInfo(userData)
    }
  }, [currentUser, userRoster])

  ///For Save Dialog Modal
  const [saveSuccessfully, setSaveSuccessfully] = useState(false)
  const [modalText, setModalText] = useState('Nothing')
  const handleCloseSave = () => {
    setModalText('')
    setSaveSuccessfully(false);
  }
  const handleShowSave = (textToShow = '') => {
    setModalText(textToShow)
    setSaveSuccessfully(true);
  }
  //////
  return <>

    {userData && isPortalAdmin(userData.atype) && <Button className='mx-1' variant="warning" onClick={handleShow}>
      Edit User Role
      <AiFillQuestionCircle style={{
        marginLeft: '4px', marginBottom: '3px',
        fontSize: '17px'
      }} data-tip={'Edit User Information'} />

    </Button>}

    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>User Roster</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown onSelect={(eventKey, event) => {
          console.log(eventKey)
          setCurrentUser(eventKey);
        }}>
          <Dropdown.Toggle variant={''}
            className={'btn-lg button-fill-theme mb-2'}
            id="dropdown-basic">
            Current User: <b>{userRoster[currentUser] ? userRoster[currentUser]['email'] : 'None'}</b>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu}>
            {/* {Object.keys(userRoster).map(user => {
              console.log('user:', user)
              if (userRoster[user].email)
                return <Dropdown.Item eventKey={userRoster[user].userID} active={currentUser == userRoster[user].userID}>{userRoster[user]['email']}</Dropdown.Item>
            })} */}
            {userEmailList.map(email => {
              // console.log('email:', email)
              let user = null
              for (const [key, value] of Object.entries(userRoster)) {
                if (value.email && value.email == email) {
                  user = value;
                }
              }
              if (user)
                return <Dropdown.Item eventKey={user.userID} active={currentUser == user.userID}>{user['email']}</Dropdown.Item>
            })}
          </Dropdown.Menu>
        </Dropdown>
        {currentUserInfo && userRoster && <Card style={{ width: '100%' }}>
          <Card.Header>User Info</Card.Header>
          <Card.Body>
            <Card.Title>{currentUserInfo.encrypted ? base64Decode(currentUserInfo['firstName']) : currentUserInfo['firstName']} {currentUserInfo.encrypted ? base64Decode(currentUserInfo['lastName']) : currentUserInfo['lastName']} <AiTwotoneUnlock style={{
              marginBottom: '5px', color: 'red',
              fontSize: '19px'
            }} data-tip={`Names are decrypted for Hub Lead Admins only.`} /></Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{currentUserInfo['email']}</Card.Subtitle>
            <Card.Text>
              Institution: <b>{currentUserInfo.institution ? currentUserInfo.institution : 'N/A'}</b><br></br>
              Nickname: <b>{currentUserInfo.nickname ? currentUserInfo.nickname : 'N/A'}</b><br></br>
              {!isPortalAdmin(currentUserInfo.atype) ?
                <div>
                  {!isEditing ?
                    <p>Account Type: <b>{allRolesRegular[currentUserInfo.atype]}</b> </p> :
                    <Dropdown className=''
                      onSelect={(eventKey, event) => {
                        setCurrentUserInfo({ ...currentUserInfo, atype: eventKey })
                      }}>
                      <Dropdown.Toggle
                        id="dropdown-basic">
                        Account Type: {allRolesRegular[currentUserInfo.atype]}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {Object.keys(allRolesRegular).map(role => {
                          return <Dropdown.Item eventKey={role}>{allRolesRegular[role]}</Dropdown.Item>
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                  <Button variant={isEditing ? 'warning' : "outline-warning"} onClick={toggleEdit} >{isEditing ? "Save" : 'Edit'}</Button>
                  {isEditing && <Button className='mx-2' variant="secondary" onClick={handleCancelEdit}>Cancel</Button>}
                </div> : <>
                  <p>Account Type: <b>Hub Lead Admin</b>
                    <div className="invalid-feedback" style={{ display: 'block' }}>This role CANNOT be modified from the web portal. Please contact the system administrator for more information.</div>
                  </p>

                </>
              }
            </Card.Text>
          </Card.Body>
        </Card>}


      </Modal.Body>
      <Modal.Footer>

        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {/* <Button variant="primary"
          onClick={() => { handleShowSave('Saved Successfully!') }}
        >
          Save Changes
        </Button> */}
      </Modal.Footer>
    </Modal>
    <Modal show={saveSuccessfully} onHide={handleCloseSave}>
      <Modal.Header closeButton>
        <Modal.Title>System Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalText}</Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleCloseSave}>
          Okay
        </Button>
        {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
      </Modal.Footer>
    </Modal>
  </>
}
function RecentlyDeletedFormComponent() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [recentDeletedForms, setRecentDeletedForms] = useState([])
  useEffect(() => {
    const q = query(collection(db, "form_library_recently_deleted"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const deletedForms = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        deletedForms.push(data);
        console.log(`Deleted Forms: `, data);
        setRecentDeletedForms([...deletedForms])
      });
    });
  }, [])
  // writeInstitutions()
  const [saveSuccessfully, setSaveSuccessfully] = useState(false)
  const [modalText, setModalText] = useState('Nothing')
  const handleCloseSave = () => {
    setModalText('')
    setSaveSuccessfully(false);
  }
  const handleShowSave = (textToShow = '') => {
    setModalText(textToShow)
    setSaveSuccessfully(true);
  }
  const [warningSuccessfully, setWarningSuccessfully] = useState(false)
  const [modalTextWarning, setModalTextWarning] = useState('Nothing')
  const handleCloseWarning = () => {
    setModalTextWarning('')
    setWarningSuccessfully(false);
  }
  const handleShowWarning = (textToShow = '') => {
    setModalTextWarning(textToShow)
    setWarningSuccessfully(true);
  }

  const [currentAction, setCurrentAction] = useState([]);
  return <>
    <ReactTooltip />

    {/* <Button className='mx-1' variant="danger" style={{
      fontSize: '15px'
    }} onClick={handleShow}>
      View Recently Deleted Forms
      <AiFillQuestionCircle style={{
        marginLeft: '4px', marginBottom: '3px',
        fontSize: '17px'
      }} data-tip={'Forms that have been deleted in the past.'} />

    </Button> */}


    <Modal show={warningSuccessfully} onHide={handleCloseWarning}>
      <Modal.Header closeButton>
        <Modal.Title>System Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre style={{ fontFamily: 'Helvetica', fontSize: 17 }}>{modalTextWarning}</pre>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleCloseWarning}>
          Close
        </Button>
        <Button variant="danger" onClick={() => {
          console.log('proceeddd', currentAction)
          if (currentAction[0] == 'restore') {
            restoreForm(currentAction[1], null, handleShowSave(`Restored ${currentAction[2]} Successfully`))
          }
          else if (currentAction[0] == 'delete') {
            deleteFormsPermanent(currentAction[1], handleShowSave(`Deleted ${currentAction[2]} Successfully`))
          }

          handleCloseWarning();
        }}>
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
    <Modal show={saveSuccessfully} onHide={handleCloseSave}>
      <Modal.Header closeButton>
        <Modal.Title>System Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalText}</Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleCloseSave}>
          Okay
        </Button>
        {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
      </Modal.Footer>
    </Modal>
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Recently Deleted Forms</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card style={{ width: '100%' }}>
          <Card.Header>Form List</Card.Header>

          <ListGroup variant="flush">
            {recentDeletedForms.map(form => (
              <ListGroup.Item>
                <Card>
                  <Card.Body>

                    <Card.Title>{form.formLongName ? form.formLongName : form.formTitle}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">ID: {form.formID}</Card.Subtitle>
                    <Card.Text>
                      Created at: <b>{formatDate(form.createdAt)}</b> <br></br>
                      Deleted at: <b>{formatDate(form.deletedAt)}</b> <br></br>
                      Deleted by: <b>{form.deletedByFull}</b>
                    </Card.Text>
                    <Button style={{ marginRight: 3 }} variant="outline-success"
                      onClick={() => {
                        const formName = form.formLongName ? form.formLongName : form.formTitle

                        const textToShow = `Would you like to restore this form to the main page?\nForm Name: ${formName}`
                        handleShowWarning(textToShow)
                        setCurrentAction(['restore', form.formID, formName])
                      }}>
                      Restore
                    </Button>
                    <Button variant="danger"
                      onClick={() => {
                        const formName = form.formLongName ? form.formLongName : form.formTitle
                        const textToShow = `Would you like to remove this form permanently?\nForm Name: ${formName}`

                        handleShowWarning(textToShow)
                        setCurrentAction(['delete', form.formID, formName])

                        // setCurrentAction(deleteFormsPermanent(form.formID), handleShowSave)
                      }}>
                      Remove Permanently
                    </Button>

                  </Card.Body>
                  <Card.Footer className="text-muted">Deleted {getDifferenceInDays(null, form.deletedAt ? form.deletedAt.toDate() : null)} day(s) ago</Card.Footer>
                </Card></ListGroup.Item>
            ))}
          </ListGroup>
        </Card>


      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {/* <Button variant="primary"
          onClick={() => { handleShowSave('Saved Successfully!') }}
        >
          Save Changes
        </Button> */}
      </Modal.Footer>
    </Modal>
  </>
}

function InstitutionOrderComponent() {

  const validationSchema = Yup.object().shape({

    institutionList: Yup.string().required('Institutions Required')
      .test('Format not followed',
        'Institution format not followed!',
        (result) => {
          // console.log(result)
          let allInstitutions = result.split('\n')
          let valid = true;
          let invalidInstitutions = []
          allInstitutions.every(inst => {
            if (!inst.includes(' (') || !inst.includes(')')) {
              console.log('invalid', inst)
              valid = false;
              // return false;
              invalidInstitutions.push(inst)
            }
            return true;

          })
          if (!valid) {
            setInvalidInstitutions([...invalidInstitutions])

          }
          else {
            setInvalidInstitutions([])

          }
          // setError('invalidInstitutions', { type: 'custom', message: `Invalid Institutions: ${invalidInstitutions.join(', ')}` })
          return valid;
          // return true;
        })

  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const methods = useForm(formOptions);
  const { handleSubmit, reset, register, setValue, formState: { errors }, setError, watch, getValues } = methods;


  // register('institution_list')

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  ///Edit Institution///
  const [institutionList, setInstitutionsList] = useState([])
  useEffect(() => {
    const docRef = doc(db, "information_sources", 'institutions');
    onSnapshot(docRef, async (docSnap) => {
      const data = docSnap.data();
      console.log('data', data)
      setInstitutionsList([...data['institution_list']])
      setValue('institutionList', data['institution_list'].join('\n'))
    })
  }, [])
  // writeInstitutions()
  const [saveSuccessfully, setSaveSuccessfully] = useState(false)
  const [modalText, setModalText] = useState('Nothing')
  const handleCloseSave = () => {
    setModalText('')
    setSaveSuccessfully(false);
  }
  const handleShowSave = (textToShow) => {
    setModalText(textToShow)
    setSaveSuccessfully(true);
  }
  const handleEdit = (data, e) => {
    e.preventDefault();
    console.log('handleEdit data', data);
    const institutionListArray = data['institutionList'].split('\n')
    console.log('institutionListArray', institutionListArray)
    writeInstitutions(institutionListArray, handleShowSave)
    handleClose();
  }
  console.log(errors)
  const [invalidInstitutions, setInvalidInstitutions] = useState([])
  useEffect(() => {
    console.log('invalidInstitutions', invalidInstitutions)
  }, [invalidInstitutions])
  return <>
    <ReactTooltip />
    <Button className='mx-1' variant="btn btn-outline-theme" onClick={handleShow}>
      Edit Institution List
      <AiFillQuestionCircle style={{
        marginLeft: '4px', marginBottom: '3px',
        // color: theme.highlightColor,
        fontSize: '17px'
      }} data-tip={'Add and Edit Institution List'} />

    </Button>

    <Modal show={saveSuccessfully} onHide={handleCloseSave}>
      <Modal.Header closeButton>
        <Modal.Title>Institution Updates</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalText}</Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleCloseSave}>
          Okay
        </Button>
        {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
      </Modal.Footer>
    </Modal>
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Edit Institutions</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {institutionList.length > 0 && <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleEdit)}>
            <div className='row'>
              <Textarea
                name={'institutionList'}
                label={'Institution List: '}
                className="mb-3 col-md-12"
                defaultValue={institutionList.join('\n')}
                required
                instructions={'One institution per line.'}
                minHeight={400}
              />
            </div>

          </form>
        </FormProvider>}
        {/* {!clickShow && institutionList.map(inst => <p>{inst}</p>)} */}
        {errors['institutionList'] && invalidInstitutions.length > 0 && <small style={{ color: 'red' }}>Invalid Input: {invalidInstitutions.join(',')}</small>} <br></br>
        {errors['institutionList'] && <small style={{ color: 'red' }}>Please double check that all institutions follow this format: <i>INSTITUTION_NAME</i> (<i>INSTITUTION_ACRONYM</i>).</small>}




      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit(handleEdit)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  </>
}
function FormOrderComponent({ formMetadata }) {
  const [currentTargetRole, setCurrentTargetRole] = useState('student');
  const [currentFormOrder, setCurrentFormOrder] = useState([]);
  const [originalFormOrder, setOriginalFormOrder] = useState([]);
  console.log('formMetadata', formMetadata)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (currentFormOrder && currentFormOrder.length > 0) {
      // console.log('currentFormOrder', currentFormOrder)
    }
  }, [currentFormOrder])

  useEffect(() => {
    // console.log('calling from formMetadata')
    const firebaseDocID = getFirebaseDocumentID(currentTargetRole)
    const formListRef = doc(db, 'automatic_form_order', firebaseDocID);

    const unsub = onSnapshot(formListRef, (doc) => {
      // console.log('calling from unsub')
      const data = doc.data();
      console.log('orderData: ', data)
      if (data && data.formOrder) {
        data.formOrder.forEach(order => {
          formMetadata.forEach(form => {
            if (form.formID == order.id) {
              order.content = form.form_questions[0].question_text
            }
          })
        })
        setCurrentFormOrder(data.formOrder);
        setOriginalFormOrder(data.formOrder);
      }
      else {
        setCurrentFormOrder([]);
        setOriginalFormOrder([]);
      }

    });
  }, [formMetadata, currentTargetRole])




  return (
    <>
      <ReactTooltip />
      <Button className='mx-1' variant="btn btn-outline-theme" onClick={handleShow}>
        Form Sequencing
        <AiFillQuestionCircle style={{
          marginLeft: '4px', marginBottom: '3px',
          // color: theme.highlightColor,
          fontSize: '17px'
        }} data-tip={'Specify forms that need to be completed in order.'} />

      </Button>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Form Sequence For <b>{allRolesRegular[currentTargetRole]}s</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-2'>
            <Dropdown onSelect={(eventKey, event) => {
              console.log(eventKey)
              setCurrentTargetRole(eventKey);
            }}>
              <Dropdown.Toggle variant={''}
                className={'btn-lg button-fill-theme mb-2'}
                id="dropdown-basic">
                Target Users: <b>{allRolesRegular[currentTargetRole]}</b>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.keys(allRolesRegular).map(role => {
                  return <Dropdown.Item eventKey={role} active={currentTargetRole == role}>{allRolesRegular[role]}</Dropdown.Item>
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* {questionObj.question_text} */}
            <Dropdown className=''
              onSelect={(eventKey, event) => {
                formMetadata.map(form => {
                  if (form.formID == eventKey) {
                    const formTitle = form.form_questions[0].question_text

                    setCurrentFormOrder([...currentFormOrder, { id: eventKey, content: formTitle }])

                  }
                })
              }}>

              <Dropdown.Toggle variant={''}
                className={'button-outline-theme mb-2'}
                id="dropdown-basic">
                Add a step
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {formMetadata && formMetadata.map(form => {
                  const formTitle = form.form_questions[0].question_text
                  const allSelectedForms = currentFormOrder.map(form => form.id);
                  if (!allSelectedForms.includes(form.formID))
                    return <Dropdown.Item eventKey={form.formID}>{formTitle}</Dropdown.Item>
                })}
              </Dropdown.Menu>



            </Dropdown>
            {currentFormOrder.length > 0 ? <FormOrderDnD formOrder={currentFormOrder} setFormOrder={setCurrentFormOrder} />
              : <div style={{ color: 'orange' }}>Order is not created for {allRolesRegular[currentTargetRole]} yet! Please click <Button variant='outline-theme' disabled size='sm'>Add a step</Button> above to start.</div>
            }
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            handleClose()
            setCurrentFormOrder(originalFormOrder);
          }}>
            Cancel
          </Button>
          <Button variant="primary" onClick={
            () => {
              handleClose();
              const firebaseDocID = getFirebaseDocumentID(currentTargetRole)
              const formListRef = doc(db, 'automatic_form_order', firebaseDocID);
              //Reset old prerequisites and next forms
              const allFormsInOrder = originalFormOrder.map(formOrder => formOrder.id)

              originalFormOrder.forEach((formOrder) => {
                console.log('formOrder ID', formOrder.id)
                const formID = formOrder.id;
                const formTitle = formOrder.content;
                let currentPrerequisites = []
                let currentPrerequisitesFull = []
                let currentNextForm = []
                let currentNextFormFull = []
                const formRef = doc(db, 'form_library', formID);
                // const allPromises = []
                //     allPromises.push(getDoc(docRef))
                console.log('finalformOrder of ' + formID, {
                  currentPrerequisites,
                  currentPrerequisitesFull,
                  currentNextForm,
                  currentNextFormFull,
                  lastEdited: serverTimestamp(),
                  formTitle
                })
                setDoc(formRef, {
                  currentPrerequisites,
                  currentPrerequisitesFull,
                  currentNextForm,
                  currentNextFormFull,
                  lastEdited: serverTimestamp(),
                  formTitle
                }, { merge: true }).then(() => {
                  if (currentFormOrder.includes(formID)) {
                    const index = currentFormOrder.indexOf(formID)
                    if (index > 0) {
                      const prerequisiteID = currentFormOrder[index - 1].id
                      const prerequisiteFull = currentFormOrder[index - 1].content

                      currentPrerequisites.push(prerequisiteID)
                      currentPrerequisitesFull.push(prerequisiteFull)
                    }
                    if (index < currentFormOrder.length - 1) {
                      const nextFormID = currentFormOrder[index + 1].id
                      const nextFormFull = currentFormOrder[index + 1].content
                      currentNextForm.push(nextFormID)
                      currentNextFormFull.push(nextFormFull)
                    }
                    const formRef = doc(db, 'form_library', formID);
                    // const allPromises = []
                    //     allPromises.push(getDoc(docRef))
                    console.log('finalformOrder of ' + formID, {
                      currentPrerequisites,
                      currentPrerequisitesFull,
                      currentNextForm,
                      currentNextFormFull,
                      lastEdited: serverTimestamp(),
                      formTitle
                    })
                    setDoc(formRef, {
                      currentPrerequisites,
                      currentPrerequisitesFull,
                      currentNextForm,
                      currentNextFormFull,
                      lastEdited: serverTimestamp(),
                      formTitle
                    }, { merge: true }).then(() => {
                      //  alert(`Form Order ${formID} Updated Successfully`)
                      //Reset all prerequisites of other forms
                    });
                  }
                });

              })
              //Set new order to Firebase
              setDoc(formListRef, { formOrder: currentFormOrder, createdAt: serverTimestamp() }, { merge: true });
              console.log('Setting prerequisites with formOrder', currentFormOrder)
              //Tie new forms to new prerequisites and new next form
              currentFormOrder.forEach((formOrder, index) => {
                if (!originalFormOrder.includes(formOrder.id)) {
                  console.log('formOrder ID', formOrder.id)
                  const formID = formOrder.id;
                  const formTitle = formOrder.content;
                  let currentPrerequisites = []
                  let currentPrerequisitesFull = []
                  let currentNextForm = []
                  let currentNextFormFull = []
                  if (index > 0) {
                    const prerequisiteID = currentFormOrder[index - 1].id
                    const prerequisiteFull = currentFormOrder[index - 1].content

                    currentPrerequisites.push(prerequisiteID)
                    currentPrerequisitesFull.push(prerequisiteFull)
                  }
                  if (index < currentFormOrder.length - 1) {
                    const nextFormID = currentFormOrder[index + 1].id
                    const nextFormFull = currentFormOrder[index + 1].content
                    currentNextForm.push(nextFormID)
                    currentNextFormFull.push(nextFormFull)
                  }
                  const formRef = doc(db, 'form_library', formID);
                  // const allPromises = []
                  //     allPromises.push(getDoc(docRef))
                  console.log('finalformOrder of ' + formID, {
                    currentPrerequisites,
                    currentPrerequisitesFull,
                    currentNextForm,
                    currentNextFormFull,
                    lastEdited: serverTimestamp(),
                    formTitle
                  })
                  setDoc(formRef, {
                    currentPrerequisites,
                    currentPrerequisitesFull,
                    currentNextForm,
                    currentNextFormFull,
                    lastEdited: serverTimestamp(),
                    formTitle
                  }, { merge: true }).then(() => {
                    //  alert(`Form Order ${formID} Updated Successfully`)
                    //Reset all prerequisites of other forms
                  });
                }



              })


            }}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default function DashboardAdmin({ role }) {
  const auth = getAuth();
  const analytics = getAnalytics();
  // logEvent(analytics, 'main_dashboard', { role: role });
  // logEvent(analytics, 'main_dashboard');

  // console.log(logEvent(analytics, 'main_dashboard', { role: role }))
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(false);
  const [formMetadata, setFormMetadata] = useState(null);
  // const [userRole, setUserRole] = useState("administrator");
  const userRole = role;
  let userRoleFull = allRolesCompat[role]
  let displayName = '';
  if (isStudentOrMentor(role)) {
    if (userData && userData.nickname && userData.nickname != '') {
      displayName = userData.nickname
    }
  }
  else {
    if (userData)
      displayName = `${userData.firstName} ${userData.lastName}`
  }

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectDestination, setRedirectDestination] = useState("/");
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
        setUser(user);
      } else {
        setRedirectDestination("/");
        setShouldRedirect(true);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const docRef = doc(db, "Users", user.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          const data = docSnap.data();
          setUserData(data);
        } else {
        }
      });
    }
  }, [user]);

  useEffect(() => {
    const formUploadRef = collection(db, "form_library");
    const q = query(formUploadRef, orderBy("createdAt", "desc"))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const users = {};
      const formMetadata = [];
      let index = 0
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        formMetadata.push(data);
      });
      setFormMetadata(formMetadata)
    });
  }, [])

  const navigate = useNavigate();
  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectDestination);
    }
  }, [shouldRedirect, redirectDestination, navigate]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const logOut = () => {
    setShow(false);
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("Signed out successfully!");
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // const formOptions = { resolver: yupResolver(validationSchema) };
  // const methods = useForm(formOptions);
  // const { handleSubmit, reset, register, setValue, formState: { errors }, watch, getValues } = methods;
  // register('institution_list')
  return (
    <>
      {/* {userData && (
        <NavBar />

      )} */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you would like to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={logOut}>
            Yes!
          </Button>
        </Modal.Footer>
      </Modal>
      {redirectDestination && (
        <main>
          <h2 className="mt-3 text-center">
            Welcome to SOAR, {displayName}!
          </h2>
          {/* <h3><b>Manual Forms</b></h3> */}
          <p></p>
          {user && userData &&
            <AvailableForms userID={user.uid}
              accountType={userData.atype}
              userData={userData}
              setFormMetadata={setFormMetadata} />}
          <h4 className="text-center" style={{ marginBottom: 15 }}>
            Form Management Dashboard
          </h4>
          <div class="wrapper-5">

            {/* <Button variant="outline-success" href="/institutionform">
              Institution Form
            </Button>{" "} */}
            {/* <Button variant="outline-info" href="/csvupload">
            Upload a form (.csv, .xlsx) / Manage Forms
          </Button>{" "}
          <Button variant="outline-secondary" href="/forms/create">
            Create a form online with Web
          </Button>{" "} */}
            {/* <Dropdown style={{}}>
              <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                Create A New Form
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="a" href="/forms/create" target="_blank" rel="noopener noreferrer">
                  Online via Web <b>(Recommended)</b>
                </Dropdown.Item>
                <Dropdown.Item as="a" href="/csvupload" target="_blank" rel="noopener noreferrer">
                  Upload CSV or Excel
                </Dropdown.Item>
              </Dropdown.Menu>

            </Dropdown> */}
            {/* <Button className='mx-1' variant="outline-secondary">
              Specify Form Order
            </Button> */}
            <FormOrderComponent formMetadata={formMetadata} />
            <InstitutionOrderComponent />
            {/* <Link to={'/readme/edit'} target="_blank" rel="noopener noreferrer">

              <Button className='mx-1' variant="outline-info">
                Edit README
                <AiFillQuestionCircle style={{
                  marginLeft: '4px', marginBottom: '3px',
                  fontSize: '17px'
                }} data-tip={'Edit README for each role'} />

              </Button>
            </Link> */}

            {/* <Link to={'/charts'} target="_blank" rel="noopener noreferrer">

              <Button className='mx-1' variant="outline-warning">
                <BetaIcon style={{ fontSize: 14 }}>Chart Visualization Dashboard</BetaIcon>
               

              </Button>
            </Link> */}

            {/* {userData && isPortalAdmin(userData.atype) && <Dropdown style={{ display: 'inline' }}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Download Data
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href="https://soar-lzlb5fvlsa-uc.a.run.app/getUsers">User Roster<b> (.CSV)</b></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>} */}
            {/* <EditUserRole userData={userData} /> */}
            {/* <Link to={'/manage-users'} target="_blank" rel="noopener noreferrer">

              <Button className='mx-1' variant="outline-warning" style={{ fontSize: 15 }}>
                User Management Dashboard
                <AiFillQuestionCircle style={{
                  marginLeft: '4px', marginBottom: '3px',
                  fontSize: '17px'
                }} data-tip={'Manage User Info, Roles, and Institutions'} />

              </Button>
            </Link> */}
            {/* <RecentlyDeletedFormComponent /> */}




          </div>
          {/* <Input
            name={'institution_list'}
            label={'Institution List: '}
            required
            instructions = {'One institution per line.'}
           /> */}


          {userData && <FormTableManager userData={userData} />}
          {/* <FormTable /> */}

        </main>
      )}
    </>
  );
}