import React, { useState, useEffect, useRef } from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { ConvertRGBtoRGBA, random_rgb, random_rgba } from "../Automatic Forms/Utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

export function LineChart({ chartData, chartRef }) {
    const [displayChartData, setDisplayChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    })
    useEffect(() => {
        if (chartData) {
            console.log(chartData)
            let rawData = chartData.data;
            if (rawData != null) {
                // Sort the keys of rawData
                const sortedKeys = Object.keys(rawData).sort();

                // Get the values in the order of the sorted keys
                const sortedValues = sortedKeys.map(key => rawData[key]);
                const labels = sortedKeys

                const randomColor = random_rgb();
                const randomAlpha = (Math.random() * (0.4 - 0.8) + 0.4).toFixed(1)
                const randomBackgroundColor = ConvertRGBtoRGBA(randomColor, 0.4)
                const randomBorderColor = ConvertRGBtoRGBA(randomColor, 1)
                let { color, alpha } = chartData;
                let borderColor = randomBackgroundColor;
                let backgroundColor = randomBackgroundColor;
                if (!alpha) {
                    alpha = 0.4;
                }
                if (color) {
                    borderColor = ConvertRGBtoRGBA(color, 1)
                    backgroundColor = ConvertRGBtoRGBA(color, 0.4)
                }
                const processedData = {
                    labels,
                    datasets: [
                        {
                            label: chartData.title,
                            data: sortedValues,
                            borderColor,
                            backgroundColor
                        },
                    ]
                }
                // console.log(processedData)
                setDisplayChartData(processedData)
                setChartOptions({
                    ...chartOptions, plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: chartData.title,
                        },
                    },
                })
            }
        }

    }, [chartData])
    return <div>
        {displayChartData && <Line options={chartOptions} data={displayChartData} ref={chartRef} id='mainChart' />}
    </div>
}
