import { Table } from "../Automatic Forms/SampleTable";
import theme from '../Theme/theme'
import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";

import { db } from "../Firebase/firebasedb";
import styled from 'styled-components'

import { doc, setDoc, getDoc, orderBy, serverTimestamp, collection, query, where, onSnapshot } from "firebase/firestore";
import { AiFillLock } from "react-icons/ai";
import "../Automatic Forms/automatic_form.css"
import { allRolesCompat, isPortalAdmin, isCampusLead, getFirebaseDocumentID, isHubLead } from "../Fixed Sources/accountTypes";
import { isAccessAllowed, parseMultiple2 } from "../Automatic Forms/Utils";
import { Link } from "react-router-dom";
import { BallTriangle, Grid, Rings, SpinningCircles, TailSpin } from "react-loading-icons";
function LockedForm({ formData,
  notSatisfied, currentPrerequisites,
  currentPrerequisitesFull, allPreviousSubmissionsID }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log('formData', formData)
  return (
    <>
      <Button style={{ margin: 3 }} variant="outline-secondary" onClick={handleShow}>
        {formData.sortIndex && formData.sortIndex < 10000 &&
          <small style={{ textDecoration: 'underline' }}>{`Step ${formData.sortIndex}`}<br></br></small>}

        {notSatisfied && <AiFillLock style={{ marginBottom: '3px', marginRight: 1 }} />}
        {formData.formTitle} {
          notSatisfied && ' (Locked)'
        }<br></br>
        {formData.startDate != '' ? <p style={{ margin: 0, padding: 0 }}>Submission open: <b>{parseMultiple2(formData.startDate)}</b></p> :
          <p style={{ margin: 0, padding: 0 }}>Submission Open: <b style={{ color: 'green' }}>Always</b></p>}
        {formData.endDate != '' ? <p style={{ margin: 0, padding: 0 }}>Submission closed: <b>{parseMultiple2(formData.endDate)}</b></p> :
          <p style={{ margin: 0, padding: 0 }}>Submission closed: <b>Never</b></p>}


      </Button>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Form Details and Requirements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData.sortIndex && formData.sortIndex < 10000 && <p style={{ textDecoration: 'underline' }}>{`Step ${formData.sortIndex}`}</p>}

          {/* {formData.formTitle} {
            notSatisfied && ' (Locked)'
          } <br></br> */}
          <h4>{notSatisfied && <AiFillLock style={{ marginBottom: '3px', marginRight: 1 }} />}
            {formData.formTitle} {
              notSatisfied && ' (Locked)'
            } <br></br></h4>
          {formData.startDate != '' ? <p style={{ margin: 0, padding: 0 }}>Submission open: <b>{parseMultiple2(formData.startDate)}</b></p> :
            <p style={{ margin: 0, padding: 0 }}>Submission Open: <b style={{ color: 'green' }}>Effective immediately</b></p>}
          {formData.endDate != '' ? <p style={{ margin: 0, padding: 0 }}>Submission closed: <b>{parseMultiple2(formData.endDate)}</b></p> :
            <p style={{ margin: 0, padding: 0 }}>Submission closed: <b>Never</b></p>}


          {
            currentPrerequisites.length > 0 && <p><br></br>
              {notSatisfied && <p style={{ color: 'red', fontWeight: 'bold' }}>Please complete the required forms to unlock this form.</p>}

              <span style={{ fontSize: "110%", textDecorationLine: 'underline', fontWeight: 'bold' }}>Prerequisites:</span> {currentPrerequisitesFull.map((pre, index) => {
                let color = 'red'
                if (allPreviousSubmissionsID.includes(currentPrerequisites[index])) {
                  color = 'green'
                }
                return <p style={{ margin: 0 }}>{pre}
                  <span style={{ color: color, fontWeight: 'bold' }}>{color == 'green' ? ' (Completed)' : ' (Incomplete)'}</span></p>
              })}
              {/* <AiFillQuestionCircle style={{ marginLeft: '2px', marginBottom: '3px', color: theme.highlightColor, fontSize: '17px' }} data-tip={'You must complete these required forms in order for this form to unlock.'} /> */}
            </p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {formData &&
            <Link to={`/forms/${formData.formID}`}
              target="_blank" rel="noopener noreferrer"><Button variant="primary" onClick={handleClose}>Go to Form (Preview only)</Button></Link>

          }
        </Modal.Footer>
      </Modal>
    </>
  );
}
const FormList = ({ formUploadHistory, previousSubmissions, currentFormOrder = [], userData, excludedFormIDs = [] }) => {
  console.log('currentFormOrder', currentFormOrder)
  console.log('excludedFormIDs', excludedFormIDs)
  const buttonStyles = ["primary", 'secondary', 'success', 'warning', 'info', 'light']
  const latestFormHistory = formUploadHistory
  let completed = 0;
  let incomplete = 0;
  //formUploadHistory can contain 90% of forms due to excluded;
  //currentFormOrder will contain original form order => needs revision;
  const revisedFormOrder = [];
  currentFormOrder.forEach(formInfo => {
    if (!excludedFormIDs.includes(formInfo.id)) {
      revisedFormOrder.push(formInfo);
    }
  })
  const allOrderID = revisedFormOrder.map(form => form.id);
  console.log('allOrderID', allOrderID)
  if (currentFormOrder.length > 0) {
    formUploadHistory.forEach((formData, index) => {
      const foundIndex = allOrderID.indexOf(formData.formID);
      if (foundIndex >= 0) {
        formData.sortIndex = foundIndex + 1
      }
      else {
        formData.sortIndex = 10000 + index
      }
    })
  }

  console.log('formUploadHistory', formUploadHistory)
  formUploadHistory.sort(function (x, y) {
    // console.log(y.currentPrerequisites.length, x.currentPrerequisites.length)
    return x.sortIndex - y.sortIndex; //sort y before x
  })
  const incompleteFormList = formUploadHistory.map((formData, index) => {
    // formData.sortIndex = index + 1;

    if (userData && formData.allowedRoles && formData.allowedRoles.includes(userData.atype)) {
      if (previousSubmissions && !Object.keys(previousSubmissions).includes(formData.formID)) {
        const allPreviousSubmissionsID = Object.keys(previousSubmissions);
        let currentPrerequisites = [];
        let currentPrerequisitesFull = [];
        let notSatisfied = false
        console.log('revisedFormOrder', revisedFormOrder)

        if (revisedFormOrder.length > 0 && allOrderID.includes(formData.formID)) {
          currentPrerequisites = index > 0 ? [revisedFormOrder[index - 1].id] : [];
          currentPrerequisitesFull = index > 0 ? [revisedFormOrder[index - 1].content] : []
        }

        incomplete += 1


        currentPrerequisites.forEach((pre, index) => {
          if (!allPreviousSubmissionsID.includes(pre)) {
            notSatisfied = true;

          }
          // if (excludedFormIDs.includes(pre)){
          //   notSatisfied = false;
          // }
        })
        if (formData.startDate != '') {
          let startDate = new Date(formData.startDate);
          let todayDate = new Date();
          if (startDate > todayDate) {
            notSatisfied = true;
          }
        }


        if (notSatisfied) {
          return <LockedForm
            formData={formData}
            notSatisfied={notSatisfied}
            currentPrerequisites={currentPrerequisites}
            currentPrerequisitesFull={currentPrerequisitesFull}
            allPreviousSubmissionsID={allPreviousSubmissionsID}
          />
        }
        return <Button style={{ margin: 3 }}
          href={!notSatisfied ? `/forms/${formData.formID}` : '#'}
          onClick={() => {
            if (notSatisfied) {
              alert("Please complete the required forms in order to open this form.")
            }
          }}
          disabled={notSatisfied} //satisfied = true => disabled = false
          variant={`${notSatisfied ? 'outline-secondary' : 'outline-theme'}`}
        // className={notSatisfied ? 'btn-outline-secondary' : 'button-outline-theme'}
        >
          {allOrderID.includes(formData.formID) && revisedFormOrder.length > 0 && formData.sortIndex < 10000 && <small style={{ textDecoration: 'underline' }}>
            {formData.sortIndex < 10000 && `Step ${formData.sortIndex}`}
            <br></br></small>}

          {notSatisfied && <AiFillLock style={{ marginBottom: '3px', marginRight: 1 }} />}
          {formData.formTitle} <br></br>
          {formData.startDate != '' ? <p style={{ margin: 0, padding: 0 }}>Submission open: <b>{parseMultiple2(formData.startDate)}</b></p>
            : <p style={{ margin: 0, padding: 0 }}>Submission open: <b style={{ color: 'green' }}>Always</b></p>}
          {formData.endDate != '' ? <p style={{ margin: 0, padding: 0 }}>Submission closed: <b>{parseMultiple2(formData.endDate)}</b></p> :
            <p style={{ margin: 0, padding: 0 }}>Submission closed: <b style={{ color: theme.highlightColor }}>Never</b></p>}

          {
            notSatisfied && ' (Locked)'
          }

        </Button>

      }
    }

    return <div></div>

  }

  )
  const completedFormList = latestFormHistory.map((formData, index) => {

    if (previousSubmissions && Object.keys(previousSubmissions).includes(formData.formID)) {
      const createdDate = previousSubmissions[formData.formID].createdAt.toDate().toDateString()
      const createdTime = previousSubmissions[formData.formID].createdAt.toDate().toLocaleTimeString('en-US');
      completed += 1
      return <Button style={{ margin: 3 }}
        // disabled={true}
        href={`/forms/${formData.formID}`}
        variant={'outline-' + 'success'}>
        {currentFormOrder.length > 0 && formData.sortIndex && formData.sortIndex < 10000 && <small style={{ textDecoration: 'underline' }}>
          {`Step ${formData.sortIndex}`}
          <br></br></small>}

        {formData.formTitle}<br></br>

        <small><i>Completed: {`${createdDate}, ${createdTime}`}</i></small>
      </Button>

    }
    return <div></div>

  })
  if (formUploadHistory.length > 0) {

    return <div>
      <hr></hr>

      {incomplete > 0 && <h3 style={{ paddingTop: 5 }}><b>Pending Forms</b></h3>}
      {incompleteFormList}

      {completed > 0 && < h3 style={{ paddingTop: 5 }}><b>Completed Forms</b></h3>
      }
      {completedFormList}
      <hr></hr>




    </div >
  }

  return <><div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <TailSpin stroke={`${theme.highlightColor}`}
      fill={`${theme.highlightColor}`}
      speed={1} width={100} height={100} />
  </div>
    <div className="text-center">
      <p>Loading Pending Forms...</p>
    </div></>
}
export default function AvailableForms({ userID, accountType, userData, setFormMetadata }) {
  // console.log('accountType', accountType)

  //Form Order Retrieval
  const firebaseDocID = getFirebaseDocumentID(accountType)
  console.log('firebaseDocID', firebaseDocID)
  const formOrderRef = doc(db, 'automatic_form_order', firebaseDocID);
  const [currentFormOrder, setCurrentFormOrder] = useState([]);

  //Form Upload History Retrieval
  const formUploadRef = collection(db, "form_library");
  // const q = query(formUploadRef, where("userID", "==", user.uid))
  const [formUploadHistory, setFormUploadHistory] = useState([]);
  // const [formDashboardList, setFormDashboardList] = useState([]);
  const [allFormMetadata, setAllFormMetadata] = useState([]);

  //Form Upload History Retrieval
  const q = query(formUploadRef, orderBy("createdAt", "desc"))
  const [previousSubmissions, setPreviousSubmissions] = useState({})
  const [excludedFormIDs, setExcludedFormIDs] = useState([])

  useEffect(() => {
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const allPromises = []
      let uniqueCreatorIDs = []
      querySnapshot.forEach((docSnapShot) => {
        const rawData = docSnapShot.data();
        const creatorID = rawData.userID;
        const docRef = doc(db, "Users", creatorID);
        if (uniqueCreatorIDs.includes(creatorID) == false) {
          allPromises.push(getDoc(docRef))
          uniqueCreatorIDs.push(creatorID);
        }
      })
      await Promise.all(allPromises).then((allDocSnaps) => {
        let formUploadHistData = []

        // let allCreatorData = {}
        // allDocSnaps.forEach(docSnap => {
        //   const data = docSnap.data();
        //   console.log(data);
        //   allCreatorData[data.userID] = data;
        //   // console.log(docSnap.data())

        // })
        let formMetadata = []
        let excludedFormIDsServer = []

        querySnapshot.forEach((docSnapShot) => {
          console.log('formData', docSnapShot.data())
          formMetadata.push(docSnapShot.data())
          // doc.data() is never undefined for query doc snapshots
          const rawData = docSnapShot.data();
          allFormMetadata[rawData.formID] = rawData;
          const createdAt = rawData.createdAt.toString();
          const formName = rawData.form_questions.length > 0 && rawData.form_questions[0].question_type == 'form_title' ? rawData.form_questions[0].question_text : 'Untitled Form'
          const status = rawData.status ? rawData.status : 'published'
          let action = status == 'published' ? "Unpublish" : 'Publish'
          const allowedRoles = rawData.allowedRoles ? rawData.allowedRoles : []

          let allowedRolesString = 'None'

          if (allowedRoles.length > 0) {
            let tempList = []
            allowedRoles.forEach(role => {
              tempList.push(allRolesCompat[role])
            })
            allowedRolesString = tempList.join(', ')
          }
          const creatorID = rawData.userID;
          // let creatorEmail = allCreatorData[creatorID] ? allCreatorData[creatorID].email : 'unknown';
          const formDomain = rawData.formDomain ? rawData.formDomain : 'Common'


          const allowedInstitutions = rawData.allowedInstitutions ? rawData.allowedInstitutions : []
          const startDate = rawData.startDate && rawData.startDate != '' ? rawData.startDate : ''
          const endDate = rawData.endDate && rawData.endDate != '' ? rawData.endDate : ''

          if (creatorID == userID || isAccessAllowed(rawData, userData)) {
            console.log('access allowed')

            const formHistData = {
              formID: rawData.formID,
              formTitle: formName,
              createdAt,
              timeStamp: rawData.createdAt,
              status: status.toUpperCase(),
              allowedRoles: allowedRoles,
              formDomain: formDomain,
              allowedInstitutions: allowedInstitutions,
              startDate,
              endDate
            }
            //Decides
            formUploadHistData.push(formHistData)
          }


        })
        if (setFormMetadata)
          setFormMetadata(formMetadata);
        setExcludedFormIDs(excludedFormIDsServer)
        // console.log('excludedFormIDsServer', excludedFormIDsServer)
        setAllFormMetadata(formMetadata);
        // setFormDashboardList(JSON.parse(JSON.stringify(formUploadHistData)))
        //Check Previous Form Submission Status
        const formUploadRef = collection(db, "automatic_form_submissions");
        const qPrevious = query(formUploadRef, where("userID", "==", userID), orderBy("createdAt", "desc"))
        const latestRetrievedForm = {}

        onSnapshot(qPrevious, (qSnapShot) => {
          if (qSnapShot.size > 0) {
            qSnapShot.forEach((doc) => {
              // console.log(doc.data());
              // doc.data() is never undefined for query doc snapshots
              const previousRawData = doc.data();
              if (previousRawData.createdAt) {
                const createdDate = previousRawData.createdAt.toDate().toDateString()
                const createdTime = previousRawData.createdAt.toDate().toLocaleTimeString('en-US');
                previousRawData.createdDateTime = `${createdDate}, ${createdTime}`
              }
              if (!Object.keys(latestRetrievedForm).includes(previousRawData.formID)) {
                latestRetrievedForm[previousRawData.formID] = previousRawData;
              }

            });

            setPreviousSubmissions({ ...previousSubmissions, ...latestRetrievedForm });
          }
          else {
            setPreviousSubmissions({});
          }
          console.log('formUploadHistData', formUploadHistData)
          setFormUploadHistory([...formUploadHistData])

        })
        //Check Form Order
        const unsub = onSnapshot(formOrderRef, (doc) => {
          // console.log('calling from unsub')
          const data = doc.data();
          console.log('orderData', data)
          if (data && data.formOrder) {
            setCurrentFormOrder(data.formOrder);
            // setOriginalFormOrder(data.formOrder);
          }
          else {
            setCurrentFormOrder([]);

          }

        });


      })
    });
    return [unsubscribe]

  }, [userID])

  return (
    <div>
      {
        !isPortalAdmin(accountType) &&
        <FormList formUploadHistory={formUploadHistory}
          previousSubmissions={previousSubmissions}
          currentFormOrder={currentFormOrder}
          userData={userData}
          excludedFormIDs={excludedFormIDs} />
      }
    </div >

  )
}